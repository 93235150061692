import React from "react";

import AlertsTemplate from "./AlertsTemplate";

const Alerts = (props) => {
    return (
        <AlertsTemplate type={props.type} />
    )
}

export default Alerts;