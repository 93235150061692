import { useEffect, useState } from "react";

import ReactApexChart from "react-apexcharts";

import { Typography } from "antd";

import { useTranslation } from "react-i18next";

function TrendingChart(props) {
    const { t }  = useTranslation();
    const { Title, Paragraph } = Typography;
    const [options, setOptions] = useState();
    const [series, setSeries] = useState();

    useEffect(()=> {

        let cats = [];
        let values = [];
        for(var i = 0; i < props.values.length; i++) {
            cats.push(i);
            values.push(props.values[i]);
        }

        setOptions({
            chart: {
                type: 'area',
                toolbar: {
                    show: false,
                },
            },
            title: { text: props?.title || 'Petete' },
            xaxis: {
                categories: [...cats]
            },
            legend: {
                show: true,
            },
          
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
            },
            fill: {
                type: "gradient",
                
              },
        })
        setSeries(
            [{
                type: 'area',
                name: t('VALUE_LABEL'),
                data: [...values],
            }]
        )
    },[]);

    return (
        <>
        {options && 
                <ReactApexChart
                className="full-width"
                options={options}
                series={series}
                />
            }
        </>
    );
}

export default TrendingChart;