const { useTranslation } = require("react-i18next")


const SoapBar = (props) => {
    const { t } = useTranslation();

    return (
        <div xs={8} sm={6} 
            style={{
                borderRadius: 10,  
                textAlign: 'center', 
                border: 'solid 1px lightgrey', 
                backgroundColor: 'white', 
                padding: '10px',
                maxHeight: 80,
                maxWidth: 80,
                marginBottom: '10px',
                boxShadow: '-5px 5px 5px lightgrey',
                lineHeight: 1
            }}>
            <span style={{ fontSize: '1.3em' }} ><b>{ props.value }</b></span><br/>
            <span style={{ color: "grey", fontSize: 10 }}>({props.unit})</span><br/>
            <span style={{ color: "grey", fontSize: 10 }}>{t(props.detail)}</span>
        </div>
    );
}

export default SoapBar;