import React, { useEffect, useState } from 'react'
import { Dropdown, Button, Menu } from 'antd'
import i18resources from '../../i18resources'
import i18next from "i18next"

//TODO:DUDA revisar esto tambien, pq al cambair de idioma suena la campana.  
const LanguageSelector = () => {
    const [currentLang, setCurrentLang] = useState(localStorage.getItem('i18nextLng') || 'en');
    const changeLang = (code) => {
       // e.preventDefault();
        setCurrentLang(code);
        i18next.changeLanguage(code);
        window.location.reload();
    }
    
    const menu= (
        <Menu className='d-flex flex-start flex-column' key='langselector'>
            { i18resources.langList.map(({code, name, country_code}) => (
                <Menu.Item key={code}>
                    <Button 
                        icon={<span className={`flag-icon flag-icon-${country_code} mx-2`}></span>} 
                        onClick={() => changeLang(code)}
                        disabled={currentLang === code ? 'disabled' : ''}
                        key={`btn_${code}`}
                    >
                        <span style={{ 'paddingLeft' : '5px'}}>{name}</span>
                    </Button>
                </Menu.Item>
            ))}
        </Menu>
    );
    
    const DropdownMenu = () => (
    
        <Dropdown key="languageSelector" overlay={menu}>
            <Button shape='circle' key='petete' icon={<i18resources.GlobeIcon2 />} style={{ "backgroundColor": "#001529"}}/>
        </Dropdown>
    );
    
    return(<DropdownMenu />)
}

export default LanguageSelector;