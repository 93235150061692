import React, { useState, useEffect } from "react";

import { Slider, Form, InputNumber, Collapse } from "antd";

import { t } from 'i18next';

const { Panel } = Collapse

const AccForm = (props) => {
    const [labelAcDc] = useState(props.type == 'brakes' ? t('LABEL_MIN_DECELERATION') : t('LABEL_MIN_ACCELERATION'))
    const [labelK] = useState(props.type == 'brakes' ? t('LABEL_DECELERATION_K') : t('LABEL_ACCELERATION_K'))
    const [acceleration, setAcceleration] = useState() 
    const [minAcceleration, setMinAcceleration] = useState()
    const [coefficient, setCoefficient] = useState();
    const [defaultValue, setDefaultValue] = useState();

    useEffect(() => {
        if(props.acceleration && Object.keys(props.acceleration).length > 0) {
            setAcceleration(props.acceleration)
            setMinAcceleration(props.acceleration.minBrakeToBeTooFast || props.acceleration.minAccelerationToBeTooFast)
            setCoefficient(props.acceleration.brakingL || props.acceleration.accelerationL)
            setDefaultValue(props.def)
        }
    }, [props.acceleration])

    const set = (e, t) => {
        let na = acceleration;
        const mode = t == 2 ? "penalization": t==1? "coefficient" : props.type == 'brakes' ? 'minBrakeToBeTooFast' : 'minAccelerationToBeTooFast'
        na[mode] = e;
        setAcceleration(na);
        if(t==0) setMinAcceleration(e);
        let trans = 0
        if(t==1) {
            if(props.type != 'brakes') 
                trans = (e===0 ? -0.014 : e===1 ? -0.007 : -0.0035) 
            else 
                trans = (e===0 ? 0.005 : e===1 ? 0.01 : 0.002) 
        }
        props.acCb({
            [props.type == 'brakes' ? 'minBrakeToBeTooFast' : 'minAccelerationToBeTooFast']: t==0 ? e : minAcceleration,
            [props.type == 'brakes' ? 'brakingL' : 'accelerationL']: t==1 ? trans : coefficient, 
            penalization: t==2 ? e : acceleration.penalization
        })
    }

    const petete = () => {
        set(defaultValue.coefficient, 1)
    }

    const marks = {
        0: t('LABEL_SOFT_MODE'),
        1: t('LABEL_MEDIUM_MODE'),
        2: t('LABEL_HARD_MODE')
      };
    const setCard = () => {
       if(!acceleration) return(<></>)
        return(
            <Collapse>
                <Panel header={t(props.type)} >
                    <Form name={props.type} onFinish={() => {console.log('')}} >
                        <Form.Item label={labelAcDc}>
                            <InputNumber 
                            name='minAcceleration'
                                onChange={(e) => { set(e, 0)}} 
                                defaultValue={minAcceleration}
                                value={minAcceleration} />
                        </Form.Item>
                        {/* <Form.Item label={t('LABEL_SENSIBILITY')} help={labelK}>
                            <InputNumber 
                            name='coefficient'
                                onChange={(e) => { set(e, 1)}} 
                                defaultValue={coefficient}
                                value={coefficient}
                                />
                        </Form.Item> */}
                        <Form.Item label={t('LABEL_SENSIBILITY')} help={labelK}>
                            <Slider 
                            name='coefficient'
                                onChange={(e) => { set(e, 1)}} 
                                defaultValue={coefficient===-0.014 || coefficient==0.005 ? 0 : coefficient===-0.007 || coefficient==0.01 ? 1 : coefficient===-0.0035 || coefficient==0.002 ? 2 : 1}
                                min={0} max={2} marks={marks}
                                />
                        </Form.Item>
                        <Form.Item label={t('LABEL_PENALIZATION')}>
                            <InputNumber 
                            name='penalization'
                                onChange={(e) => { set(e, 2)}} 
                                step={0.1}
                                defaultValue={acceleration?.penalization}
                                value={acceleration?.penalization}
                                min={0} max={100} />
                        </Form.Item>
                    </Form>
                </Panel>
            </Collapse>
        )
    }

    return(
        setCard()
    )
}

export default AccForm;