import React, { useEffect, useState } from 'react'

import { Form, InputNumber, Row, Col, Divider, Tooltip } from 'antd';

import { InfoCircleOutlined }  from "@ant-design/icons";

import '../../../assets/css/speedsTable.css'
import { t } from 'i18next';

const SpeedLine = (props) => {
    const [speeds, setSpeeds] = useState({});
    const [penalization, setPenalization] = useState()
    const [lapses, setLapses] = useState();
    const [minTimeAsOutlaw, setMinTimeAsOutlaw] = useState()
    const [k, setKey] = useState();
    const { spCb, coCb } = props;
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '> ${min} and < ${max}',
        },
    };

    useEffect(() => {
        if(props?.speed) {
            setSpeeds(props.speed.groups)
            setPenalization(props.speed.penalization)
            setLapses(props.speed.lapses);
            setKey(props.speed.limit)
            setMinTimeAsOutlaw(props.speed.activationTime)
        }
    }, [props.speed])

    const updateSpeed = (e, l) => {
        if(!e || e == undefined || e < 0) return;
        let ns = [...speeds];
        ns[l] = e;
        setSpeeds(ns);
        spCb(ns, k, 'groups');
    }
    const updatePenalization = (e, l) => {
        if(!e || e == undefined || e < 0) return;
        let ns = [...penalization];
        ns[l] = e;
        setPenalization(ns);
        spCb(ns, k, 'penalization');
    }

    const updateLapses = (e, l) => {
        if(!e || e == undefined || e < 0) return;
        let ns = [...lapses];
        ns[l] = e;
        setLapses(ns);
        spCb(ns, k, 'lapses');
    }

    const updateMinTimeAsOutlaw = (e) => {
        if(!e || e == undefined || e < 0) return;
        let ns = minTimeAsOutlaw;
        ns = e;
        setMinTimeAsOutlaw(ns);
        spCb(ns, k, 'activationTime');
    }

    /* const setMTAO = (e, s) => {
        let type = '';
        setMinTimeAsOutlaw(e);
        type = 'minTimeAsOutlaw'
//        cpCb(e, type);
    } */

    const setLapsesForm = () => {
        if(!lapses) return(<></>)
        return(
            <Row>
                {lapses.map((ele, idx) => {
                    if(idx > 0) {
                        return(
                            <Col span={4}>
                                {idx > 1 ?
                                <Form.Item 
                                    name={[idx]} 
                                    rules={[{ type: 'number', min: 0, max: 100000000 }]} 
                                    className={`${idx>1?`bg_${idx}`:''} mb5`}
                                >
                                    <InputNumber
                                        defaultValue={ele}
                                        style={{border:'none', color:'black'}}
                                        className={idx<2?'dspNone':''}
                                        onChange={(e) => { updateLapses(e,idx)}}
                                        step='1'
                                        min={0}
                                        max={100000000}
                                        />
                                </Form.Item>
                                :
                                <Form.Item name={[idx]} rules={[{ type: 'number', min: 0, max: 100000000 }]} className={`bg_${idx} mb5`} style={{width: "100%"}}>
                                        <span style={{padding: "0 11px"}}>∞</span>
                                </Form.Item>
                                }
                            </Col>
                        )
                    }
                })}
                <Col span={4}>
                    <Form.Item name={'v_info'} className={`mb5`} style={{width: "100%"}}>
                        <Tooltip title={t('SPEED_LAPSE_TOOLTIP')} placement="left">
                            <InfoCircleOutlined className='info-icon' />
                        </Tooltip>
                    </Form.Item>
                </Col>
            </Row>
        )
    }

    const setPenaForm = () => {
        if(!penalization) return(<></>)
        return(
            <Row>
                {penalization.map((ele, idx) => {
                    if(idx > 0)
                    return(<Col span={4}>
                        {idx >1 ? 
                        <Form.Item name={[idx]} 
                            rules={[{ type: 'number', min: 0, max: 100 }]} 
                            className={`${idx>0?`bg_${idx}`:''}  mb5`}
                        >
                            <InputNumber 
                                defaultValue={ele} 
                                style={{border:'none'}} 
                                className={`${idx===0?'dspNone':''}`} 
                                onChange={(e) => { updatePenalization(e,idx)}}
                                step='0.1'
                                min={0}
                                max={100}
                                />
                        </Form.Item>
                        :
                        <Form.Item name={[idx]} rules={[{ type: 'number', min: 0, max: 100000000 }]} className={`bg_${idx} mb5`} style={{width: "100%"}}>
                            <span style={{padding: "0 11px"}}>0</span>
                        </Form.Item>
                        }
                    </Col>)
                })}
                <Col span={4}>
                <Form.Item name={'v_info'} className={`mb5`} style={{width: "100%"}}>
                        <Tooltip title={t('SPEED_PENALIZATION_TOOLTIP')} placement="left">
                            <InfoCircleOutlined className='info-icon' />
                        </Tooltip>
                    </Form.Item>
                </Col>
            </Row>
        )
    }

    const drawDrow = () => {
        if(Object.keys(speeds).length <= 0 ) {
            return(<></>)
        }
        const keys = Object.keys(speeds);
        return(
            <Row span={24}>
                {keys.map((key, idx) => {
                    return(<Col span={4}>
                        <Form.Item name={key} 
                            className={`bg_${idx+1} mb5`} 
                            rules={[{ type: 'number', min: 0, max: 250 }]} 
                        >
                            <InputNumber 
                                defaultValue={speeds[key]}
                                style={{border:'none'}} 
                                onChange={(e) => { updateSpeed(e,idx)}}
                            />
                        </Form.Item>
                    </Col>)
                })}
                <Col span={4}>
                <Form.Item name={'v_info'} className={`mb5`} style={{width: "100%"}}>
                        <Tooltip title={t('SPEED_RANGE_TOOLTIP')} placement="left">
                            <InfoCircleOutlined className='info-icon' />
                        </Tooltip>
                    </Form.Item>
                </Col>
            </Row>
        )
    }

    const label = () => {
        return (
            <Col style={{display:"flex"}}>
                <span> {t('TIME')}</span>
                <Tooltip title={t('SPEEDS_ACTIVATIONTIME_TOOLTIP')}>
                    <InfoCircleOutlined className='info-icon' />
                </Tooltip>
            </Col>
        )
    }

    return(
        <>
            {minTimeAsOutlaw &&
                <>
                    <Row style={{width:"100%", borderBottom: `${k < 120 ? '1px solid lightgrey' : ''}`, padding: "5px 0 20px 0"}} id={`row_${k}`}>
                        <Col span={4} className='signal'>
                            <img src={`../../../assets/img/v4/icon_limit_${k}.png`} alt='The good driver'/>
                        </Col>
                        <Col span={20}>
                            <Col span={24} offset={1} style={{marginTop: "15px"}}>
                                {drawDrow()}
                                {setLapsesForm()}
                                {setPenaForm()}
                                <Form.Item label={label()} className='ta' >
                                    <InputNumber 
                                        onChange={(e) => { updateMinTimeAsOutlaw(e) }} 
                                        defaultValue={minTimeAsOutlaw}
                                        min={0} />
                                </Form.Item>
                            </Col>
                        </Col>
                    </Row>
                    <Row><Col><Divider /></Col></Row>
                </>
            }
        </>
    )
}
export default SpeedLine;