import React, { useState, useEffect } from "react";

import { Form, InputNumber, Row, Collapse } from "antd";

import { t } from 'i18next';

const { Panel } = Collapse;

const Touchs= (props) => {
    const { touchCb } = props;
    const [touchs, setTouchs] = useState() 
    useEffect(() => {
        if(props.touchs && Object.keys(props.touchs).length > 0) {
            setTouchs(props.touchs)
        }
    }, [props.touchs])

    const set = (e, s) => {
        let nt = touchs;
        const mode = s==0? "cuantity" : s==1? "penalization" : "minPhoneIntervalUsage"
        nt[mode] = e;
        setTouchs(nt);
        touchCb({cuantity: s==0?e:touchs.cuantity, penalization: s==1?e:touchs.penalization, minPhoneIntervalUsage: s==2 ? e : touchs.minPhoneIntervalUsage});
    }

    const getForm = () => {
       if(!touchs) return(<></>)
        return (
            <Collapse className="peteteeeee">
                <Panel header={t('TOUCHS')}>
                    <Form name="touchsForm" onFinish={() => {console.log('')}} /* initialValues={{mstca: minSpeedToCalculateAcc, mtao: minTimeAsOutlaw }} */>
                        <Form.Item label={t('TOUCHS_CUANTITY')}>
                            <InputNumber 
                                onChange={(e) => { set(e, 0) }}
                                defaultValue={touchs?.cuantity}
                                min={0}/>
                        </Form.Item>
                        <Form.Item label={t('LABEL_PENALIZATION')}>
                            <InputNumber 
                                step={0.1}
                                onChange={(e) => { set(e, 1) }} 
                                defaultValue={touchs?.penalization}
                                min={0} />
                        </Form.Item>
                        <Form.Item label={t('TIME')} help={t('TOUCHS_LAPSE')}>
                            <InputNumber 
                                step={1}
                                onChange={(e) => { set(e, 2) }} 
                                defaultValue={touchs?.minPhoneIntervalUsage}
                                min={0} />
                        </Form.Item>
                    </Form>
                </Panel>
            </Collapse>
        )
    }
    return (
        getForm()
    )
}

export default Touchs;