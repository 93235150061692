import { Spin } from 'antd';
import { Row, Col, Layout } from 'antd';

const Loading = () => {
    return (
        <Layout type='flex' style={{position: 'absolute', top: 0, left: 0, zIndex: 100, width: '100%'}}>
            <Row type="flex" style={{width:'100%', height: '100vh',alignItems: 'center', textAlign: 'center', backgroundColor: 'RGBA(0,0,0,0.3)'}}>
                <Col md={24}>
                    <Spin size='large'/>
                </Col>
            </Row>
        </Layout>
    )
}
           
export default Loading;