import React, { useEffect, useState } from 'react';

import { Layout, Button, Row, Col, Collapse } from 'antd';
import MyMenu from '../components/Menu';
import MyHeader from '../components/Header';

import { getDocu, setDocu } from '../context/firebaseConfig';

import { useTranslation } from 'react-i18next';
import SpeedsTable from '../components/Config/SpeedsTable';
import AccForm from '../components/Config/Accelerations';
import CommonsProps from '../components/Config/CommonProps';
import Touchs from '../components/Config/Touchs';
import Curves from '../components/Config/Curves';
import { useFirebaseAuth } from '../context/AuthContext';


const { Footer, Content } = Layout

const ConfigControl = () => {
    const { t } = useTranslation();
    const [speedsTable, setSpeedsTable] = useState(null);
    const [touchs, setTouchs] = useState();
    const [acceleration, setAcceleration] = useState();
    const [deceleration, setDeceleration] = useState();
    const [scoring, setScoring] = useState();
    const [defaultScoring, setDefaultScoring] = useState();
    const [minSpeedToCalculateAcc, setMinSpeedToCalculateAcc] = useState();
    const [curves, setCurves] = useState();
    const [btnClass, setBtnClass] = useState('disabled');
    const [user] = useState(useFirebaseAuth());

    useEffect(() => {
        const petete = (async() => {
            const defscr = await getDocu({ coll: 'config', docu: 'DEFAULT'})
            setDefaultScoring(defscr);        
            const scr = await getDocu({ coll: 'config', docu: user.service.toUpperCase()}); //window.localStorage.getItem('serviceID')})
            setScoring(scr || defscr);     
        });
        petete();
    },[])

    useEffect(() => {
        if(scoring) {
            setTouchs(scoring?.touchs);
            setAcceleration(scoring?.acceleration);
            setDeceleration(scoring?.deceleration);
            setMinSpeedToCalculateAcc(scoring?.minSpeedToCalculateAcc);
            setCurves(scoring?.curves)
            setSpeedsTable(scoring?.speeds);
        }
    }, [scoring]);

    const speedsCallback = (vs, k, m) => {
        let scr = scoring;
        let nee = scr.speeds.findIndex((ele) => ele.limit == k);
        if(nee < 0) {
            console.log(`Error índice no válido ${nee}`);
            return;
        }
        scr.speeds[nee][m] = vs;
        setBtnClass('')
        setScoring(scr);
    }

    const accelerationCallback = (a) => {
        let nsc = scoring;
        nsc.acceleration = a;
        setBtnClass('')
        setScoring(nsc);
    }

    const decelerationCallback = (d) => {
        let nsc = scoring;
        nsc.deceleration = d;
        setBtnClass('')
        setScoring(nsc);
    }

    const commonsCallback = (v, t) => {
        let nsc = scoring;
        nsc[t] = v;
        setBtnClass('')
        setScoring(nsc);        
    }

    const touchsCallback = (v, t) => {
        let nsc = scoring;
        nsc.touchs = v;
        setBtnClass('')
        setScoring(nsc);
    }

    const curvesCallback = (v, t) => {
        let nsc = scoring;
        nsc.curves = v;
        setBtnClass('')
        setScoring(nsc);
    }

    const saveScoring = async () => {
        setBtnClass('disabled')
       await setDocu({ coll: 'config', docu: user.service.toUpperCase(), data: scoring}); //`${window.localStorage.getItem('serviceID')}`, data: scoring})
    alert(`${t('CHANGES_SAVED')}`)
        // alert(`Los cambios de momento no se guardan mientras este en desarrollo el método de puntuación con los nuevos valores`)
    }

    if(!scoring) return <></>
    else { return (
        <React.Fragment>
            {/* <Layout>
                <MyHeader title={t('BUTTON_CONFIG_PANEL')} />
                <Content style={{display:"flex"}}>
                    <MyMenu /> */}
                    <div style={{marginTop: "10px", margin: '0 auto', width: "100%"}}>
                        <Row gutter={[0, 16]}>
                            <Col span={22} offset={1} >
                                <SpeedsTable 
                                    speedsTable={scoring?.speeds} 
                                    spCb={speedsCallback} 
                                />
                                <CommonsProps minSpeedToCalculateAcc={minSpeedToCalculateAcc} cpCb={commonsCallback} />
                                <AccForm type='brakes' acceleration={deceleration} acCb={decelerationCallback} def={defaultScoring.deceleration} />
                                <AccForm type='accelerations' acceleration={acceleration} acCb={accelerationCallback} def={defaultScoring.acceleration} />
                                <Touchs touchs={touchs} touchCb={touchsCallback} def={defaultScoring} />
                                <Curves curves={curves} cvCb={curvesCallback} />
                            </Col>
                        </Row>
                        <Row gutter={[0, 16]}>
                            <Col style={{ width: "100%", textAlign:"center", margin: "15px 0 " }}>
                                <Button className={btnClass} onClick={saveScoring} disabled={btnClass}>{t('BUTTON_SAVE')}</Button>
                            </Col>
                        </Row>
                    </div>
               {/*  </Content>
                <Footer style={{position: 'relative', bottom: 0, width: "100%"}}/>
            </Layout> */}
        </React.Fragment>
    )}
}

export default ConfigControl;