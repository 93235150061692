import React from "react";

const { useTranslation } = require("react-i18next");

const HorizontalBar = (props) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <span style={{color: 'green'}}>{t(props.title)}</span>
            <div style={{width: '90%', position: 'relative', marginBottom: '10px'}} >
                <div style={{width: '100%', height: '7px', backgroundColor:'lightgray', borderRadius: '5px', position:'absolute', top: '0px'}} />
                <div style={{width: `${props.percent}%`, height: '7px', backgroundColor:'green', borderRadius: '5px', position:'absolute', top: '0px'}} />
            </div>
        </React.Fragment>
    );
}

export default HorizontalBar;