import React, { useState, useEffect } from "react";

import { Form, InputNumber, Row, Collapse } from "antd";

import { t } from 'i18next';

const { Panel } = Collapse;

const CommonsProps= (props) => {
    const { cpCb } = props;
    
    const [minSpeedToCalculateAcc, setMinSpeedToCalculateAcc] = useState();

    useEffect(()=> {
        if(props.minSpeedToCalculateAcc) setMinSpeedToCalculateAcc(props.minSpeedToCalculateAcc);
    }, [props.minSpeedToCalculateAcc])

    const set = (e, s) => {
        let type = '';
        switch(s) {
            case 0: // MIN_SPEED_TO_CALCULATE_ACCELERATION
                setMinSpeedToCalculateAcc(e);
                type = 'minSpeedToCalculateAcc'
                break;
           
        }
        cpCb(e, type);
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    if(!minSpeedToCalculateAcc) return(<></>)
    return(
        <Collapse>
            <Panel header={t('COMMON_PROPS')}>
                <Form name="commonPropsForm" onFinish={() => {console.log('')}} >
                    <Form.Item label={t('SPEED')} help={t('MIN_SPEED_TO_CALCAULATE_ACCELERATION')}>
                        <InputNumber 
                            step={1}
                            onChange={(e) => { set(e, 0) }}
                            defaultValue={minSpeedToCalculateAcc}
                            min={0}/>
                    </Form.Item>
                   
                </Form>
            </Panel>
        </Collapse>
    )
}

export default CommonsProps;