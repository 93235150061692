import { Outlet, Navigate } from 'react-router';
import {useFirebaseAuth} from './context/AuthContext.js';

const MenuInterceptor = (props) => {
  const a = useFirebaseAuth(); 
  let go = true;
  switch (props.p) {
    case 'dashboard':
    case 'pittpanel':
    case 'simulatorpanel':
      go = a?.rol?.indexOf('ADMIN') > -1 || a?.rol?.indexOf('MANAGEMENT') > -1;
      break;
    case 'alerts':
    case 'cancelled':
    case 'closed':
    case 'mapped':
    case 'watch':
      go = a?.rol?.indexOf('ADMIN') > -1 || a?.rol?.indexOf('OPERATOR') > -1;
      break;
  }

  // Si coincide devuelve 0, si no coincide devuelve -1
  // console.log(a?.rol?.indexOf('ADMIN'))
  // console.log(a?.rol?.indexOf('OPERATOR'))
  // console.log(a?.rol?.indexOf('MANAGEMENT'))
  return  go ? <Outlet /> : <Navigate to='/login' />;
}

export default MenuInterceptor