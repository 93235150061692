import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { useTranslation } from 'react-i18next';
import { Spin } from "antd";

import { useFirebaseAuth } from "../../context/AuthContext";

import '../../assets/css/statisticChart.css'

function DChart(props) {
  const { t } = useTranslation();
  const { Title, Paragraph } = Typography;

  const [user] = useState(useFirebaseAuth())
  const [yesterdayStat, setYesterdayStat] = useState(null)
  const [monthStat, setMonthsStat] = useState(null)
  const [yearStat, setYearStat] = useState(null)
  const [customStat, setCustomStat] = useState(null)
  const [options, setOptions] = useState(null);
  const [series, setSeries] = useState(null);
  const [chartType, setChartType] = useState('donut')
  const [labels, setLabels] = useState()
  
  // Cargamos items y series que son los datos en bruto
  useEffect( ()=> {
    const doIt = async() => {
      const d = new Date(Date.now() - 3600*24*1000);
      const day = `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}`;
      if(props.type == 'D') {
        let d = null;
        const dayStat = JSON.parse(window.localStorage.getItem(`day_${day}`));
        let foundy = false;
        const dat = new Date(Date.now() - 3600*24*1000);
        const yesterdaImTomorrowEvening = `${dat.getFullYear()}-${(dat.getMonth()+1).toString().padStart(2, '0')}-${(dat.getDate()).toString().padStart(2, '0')}`;
        for(var i = 0; !foundy && i < dayStat.length; i++) {
          d = dayStat[i];
          foundy = yesterdaImTomorrowEvening == dayStat[i].date;
        }
        setYesterdayStat([d]);
      } else if(props.type == 'M') {
        let m = null;
        const mms = JSON.parse(window.localStorage.getItem(`month_${day}`));
        let foundy = false;
        for(var i = 0; !foundy && i < mms?.length; i++) {
          m = mms[i];
          foundy = new Date().getMonth() == new Date(mms[i].date).getMonth();
        }
        setMonthsStat([m]);
        // setMonthsStat(JSON.parse(window.localStorage.getItem(`month_${day}`)));
      } else if(props.type == 'Y') {
  /*       let mYear = window.localStorage.getItem(`mYear_${day}`);
        if(!mYear) { 
          mYear = await props.loadStat(day, null, );
          window.localStorage.setItem(`mYear_${day}`, JSON.stringify(mYear));
        } */
        setYearStat(JSON.parse(window.localStorage.getItem(`year_${day}`)))
      } else if(props.type == 'C') {
        setCustomStat(props.data)
      }
    }
    doIt();
  }, [user, props.dataReady])


  // Estadistica last Week
  useEffect(()=>{
    let options = {
      chart: { type: chartType, width: "100%", height: "auto", toolbar: { show: false }},
      plotOptions: { pie: { customScale: 1 }},
    };
    let categories = []
    let series = []
    const lws = (props.type === 'D' ? yesterdayStat : props.type === 'M' ? monthStat : props.type === 'Y' ? yearStat : customStat )?.filter(e => e?.service === user?.service)
    for(var i = 0; i < lws?.length; i++){     
      let scores =  typeof lws[i]?.scores == 'string' ? JSON.parse(lws[i].scores) : lws[i]?.scores;
      if(!scores) {
        const data = lws[i]?.data;
        scores = (typeof data == 'string' ? JSON.parse(data) : data)?.scores;
      }
      if(scores) {
        const keys = (Object.keys(scores)).sort();
        for(var j = 0; j < keys?.length; j++) {
          const key = keys[j];
          if(scores[key] != undefined && scores[key] != NaN && scores[key] > 0) {
            categories.push(key);
            series.push(scores[key] || 0);
          }
        }
      }
    }
    setSeries(series);
    options.labels = categories;
    setOptions(options);
  }, [yesterdayStat, yearStat, monthStat, customStat])

  const drawChart = () => {
  //  console.log(props.type, options, series)
    return ( 
      <ReactApexChart
        className="donut-chart"
        options={options}
        series={series}
        /* labels={labels} */
        type={chartType}
        height={500}
      />
    )
  }

  return (
    <>
      { series?.length ?
        <>
        {props.type != 'C' ? 
          <div className="chart-vistior">
            <Title level={5}>{t('INCIDENTS') + ' ' + 
              (props.type=='D'?(t('YESTERDAY')):props.type=='M'?(t('MONTH')):(t('YEAR'))).toLowerCase()}</Title>
          </div>
          : '' }
          <div id="chart" >
            {drawChart()} 
          </div>
          <div className="chart-vistior">
            {/* <Paragraph className="lastweek">
              {props.type == '7D'? t('MEDIUMS_7D_PARAGRAPH'): t('MEDIUMS_MY_PARAGRAPH')}
            </Paragraph> */}
          </div>
        </>
      : 
        <div className="spin-bar-chart"><Spin size="large" /></div>
      }
    </>
  )
}

export default DChart;
