const BDS = {
    "pus": { 
        "apiKey": "AIzaSyCYHFGoFsKwrhIkWJLtoqO72rhNI1EFp2E",
        "authDomain": "autohelpplus.firebaseapp.com",
        "projectId": "autohelpplus",
        // storageBucket: "autohelpplus.appspot.com",
        // messagingSenderId: "397303597018",
        "appId": "1:397303597018:web:bbda8762dcd33a0e20a2e3",
        // measurementId: "G-W3ZQPVZXW2"
    },
    "ahp": {
        "apiKey": "AIzaSyCYHFGoFsKwrhIkWJLtoqO72rhNI1EFp2E",
        "authDomain": "autohelpplus.firebaseapp.com",
        "projectId": "autohelpplus",
        // storageBucket: "autohelpplus.appspot.com",
        // messagingSenderId: "397303597018",
        "appId": "1:397303597018:web:bbda8762dcd33a0e20a2e3",
        // measurementId: "G-W3ZQPVZXW2"
    },    
    "rep": {
        "apiKey": "AIzaSyCTOLODmKp3o2p4DKcuqXMV0CxZLbooqC8",
        "authDomain": "remotoplus-kaps.firebaseapp.com",
        "projectId": "remotoplus-kaps",
        // "storageBucket": "remotoplus-kaps.appspot.com",
        // "messagingSenderId": "284815132732",
        "appId": "1:284815132732:web:08ed31cbe5401425cd5b39",
        // "measurementId": "G-JDL1CBRR8N"
    },
    "api-kaps": {
        "apiKey": "AIzaSyCIH1HtwhESYQoz9_JDLDFqxIsXz1YfELw",
        "authDomain": "api-kaps.firebaseapp.com",
        "projectId": "api-kaps",
        "appId": "1:222654951856:web:ee3a8496bc560831d2a675"
    },
    "tgd": {
        "apiKey": "AIzaSyAB0pH-vHsji3_tR4xqEl8ZaZ7xvZ0ZOEI",
        "authDomain": "the-good-driver.firebaseapp.com",
        "projectId": "the-good-driver",
        "appId": "1:258587131765:web:568bc9a70085482e2c1d3c"
    },
    "snowfy": {
        "apiKey": "AIzaSyBQrwegYWQEmOBBsdgv8qbfR7bv0SyP9lo",
        "authDomain": "snowfy-kaps.firebaseapp.com",
        "projectId": "snowfy-kaps",
        "appId": "1:365142813166:web:f192d14ced5f5f692106ff"
    },
    "remoto": {
        "apiKey": "AIzaSyABvFLtT4eprFwakTWjKaq0yeuKG3oOd10",
        "authDomain": "remoto-2.firebaseapp.com",
        "projectId": "remoto-2",
        "appId": "1:421530974832:web:dad01c47cefed514",
    }, 
    "caser": {
        "apiKey": "AIzaSyAB0pH-vHsji3_tR4xqEl8ZaZ7xvZ0ZOEI",
        "authDomain": "the-good-driver.firebaseapp.com",
        "projectId": "the-good-driver",
        "appId": "1:258587131765:web:568bc9a70085482e2c1d3c"
    },
    "caj": {
        "apiKey": "AIzaSyCT4ThPDa78PR6u9B_1cO-YGmJLfg7KXi0",
        "authDomain": "caser-autos-jovenes.firebaseapp.com",
        "projectId": "caser-autos-jovenes",
        // storageBucket: "caser-autos-jovenes.appspot.com",
        // messagingSenderId: "88680457821",
        "appId": "1:88680457821:web:58ed8852681009820ccfb4",
        // measurementId: "G-GS5GRT6MZC"
    },
    "casercc": {
        apiKey: "AIzaSyDyxyFHydpDOwafNyaL4NhG5UFmYYlycvw",
        authDomain: "caser-cc.firebaseapp.com",
        projectId: "caser-cc",
        // storageBucket: "caser-cc.appspot.com",
        // messagingSenderId: "1060857729405",
        appId: "1:1060857729405:web:eb0d9488e7f85fc1047c14",
        // measurementId: "G-JJ3BYSL0RK"
    }
    //TODO este panel solo tiene en los dominios dados de alta: ahp, caj, tgd, rep y snowfy. RACVN, Remoto2 y CaserCC no estan como dominios, igual se podrían quitar de aqui... o habrá que meterlos en Hosting si migramos a este panel... revisado su funcionamiento ok por si acaso...
};

export default BDS;