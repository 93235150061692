import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "antd";

import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useTranslation } from "react-i18next";

import { getHook, getDocu } from "../context/firebaseConfig";
import { useFirebaseAuth } from "../context/AuthContext";

import MyHeader from "../components/Header";
import MyMenu from "../components/Menu";


const {Content} = Layout;

const containerStyle = {
    width: '100%',
    height: '90vh'
  };

const MappedAlerts = () => {
    const nav = useNavigate();
    const {t} = useTranslation();
    const [user] = useState(useFirebaseAuth());
    const [markers, setMarks] = useState([]);
    //Establecemos aqui el mapa de España completo por si no hubiese coordenadas y q no de error setCenter
    const [center, setCenter] = useState({}); //{lat: 34.5, lng: -3.5}
    const [zoom, setZoom] = useState(); //5.5
      
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDn38IJ8ElyvBI0NJp9Psf0KJeW_ajGsk4" //"AIzaSyA1T5gTSGarzG3wFv1zo1UE-th6wDkBAJE"
    })
    
    useEffect(() => {
        // desde el contexto establecemos las coordenadas del mapa a mostrar
        setCenter(user?.configs?.mapCenter);
        setZoom(user?.configs?.mapZoom);
    }, [user])

    useEffect(() => {
        const unsubscribe = getHook({coll: 'alerts', order: 'alertId', service: user.service, lim: 100},
            (querySnapshot) => {
                const lst = querySnapshot.docs.map(docSnapshot => docSnapshot.data());
                setMarks(lst);
            },
            (error) => alert('kk de la vaca')
        );
        return unsubscribe;
    }, [setMarks]);

    // useEffect(() => {
    //     const getData = async () => {
    //         const service = await getDocu({ coll: 'services', docu: user.service});
    //         console.log(service)
    //         if(!service) console.log('No hay service configurado en BD');
    //         setCenter(service?.mapCenter || {lat: 34.5, lng: -3.5});
    //         setZoom(service?.mapZoom || 5.5);
    //         console.log(center)
    //         console.log(zoom)
    //     }
    //     getData();
    // }, []);

    return (
        <React.Fragment>
            {/* <Layout>
                <MyHeader title={t('BUTTON_MAPPED_ALERTS')}/>
                <Content style={{display: "flex"}}>
                    <MyMenu /> */}
                    {isLoaded &&
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={zoom}
                            //onLoad={onLoad}
                            //onUnmount={onUnmount}
                        >
                            {markers.map(l => (
                                <Marker 
                                    position={{lat:l.latitude || l.lat, lng:l.longitude || l.long}} 
                                    icon={`/assets/img/${l.viewed?'':'no'}visto.gif`} 
                                    onClick={() => nav('/watch', { state: { data:l, type: 'alerts', petet:'si'}})}
                                />
                            ))}
                        </GoogleMap>
                    }
               {/*  </Content>
            </Layout> */}
        </React.Fragment>
    )
}

export default MappedAlerts;