import { Route, Routes } from 'react-router-dom'

import { Layout } from 'antd';

import Landing from './views/Landing'
import Login from './views/Login'
import Alerts from './views/Alerts'
import Cancelled from './views/Cancelled';
import Closed from './views/Closed';
import MappedAlerts from './views/MappedAlerts';
import ConfigControl from './views/ConfigControl';
import Simulator from './views/Simulator';

import ProtectedRoute from './ProtectedRoute'
import MenuInterceptor from './MenuInterceptor';

import Dashboard from './views/Dashboard'
import NotFound from './views/NotFound'

import { AuthContext } from './context/AuthContext'
import WatchAlert from './views/WatchAlert';
import UserTravels from './views/UserTravels';

const Views = () => {
    return (
        <AuthContext>
            <Routes>
                {/* <Route exact path='/' element={<Landing />} key='toute_1'/> */}
                <Route exact path='/' element={<Login />} key='toute_1' />
                <Route path='/login' element={<Login />} key='toute_2' />

                <Route element={<ProtectedRoute />} key='toute_3'>
                    <Route element={<MenuInterceptor p='dashboard' />} key='toute_31'>
                        <Route path='/dashboard' element={<Dashboard />} key='toute_4' />
                    </Route>
                    <Route element={<MenuInterceptor p='pittpanel' />} key='toute_37'>
                        <Route path='/pittpanel' element={<ConfigControl />} key='toute_10' />
                    </Route>
                    <Route element={<MenuInterceptor p='simulatorpanel' />} key='toute_37'>
                        <Route path='/simulatorpanel' element={<Simulator />} key='toute_11' />
                    </Route>
                    <Route element={<MenuInterceptor p='usertravels' />} key='toute_37'>
                        <Route path='/usertravels' element={<UserTravels />} key='toute_12' />
                    </Route>
                    <Route element={<MenuInterceptor p='alerts' />} key='toute_32'>
                        <Route path='/alerts' element={<Alerts type='alerts' />} key='toute_5' />
                    </Route>
                    <Route element={<MenuInterceptor p='cancelled' />} key='toute_33'>
                        <Route path='/cancelled' element={<Cancelled type='cancelled' />} key='toute_6' />
                    </Route>
                    <Route element={<MenuInterceptor p='closed' />} key='toute_34'>
                        <Route path='/closed' element={<Closed type='closed' />} key='toute_7' />
                    </Route>
                    <Route element={<MenuInterceptor p='mapped' />} key='toute_35'>
                        <Route path='/mapped' element={<MappedAlerts />} key='toute_8' />
                    </Route>
                    <Route element={<MenuInterceptor p='watch' />} key='toute_36'>
                        <Route path='/watch' element={<WatchAlert />} key='toute_9' />
                    </Route>
                </Route>
                
                <Route path='*' element={<NotFound />} key='toute_404' />
            </Routes>
        </AuthContext>
    )
}

export default Views;