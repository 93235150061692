import { useState, useEffect } from "react";
//import moment from 'moment'
import { RocketOutlined, TeamOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import { Spin } from "antd";

import { useFirebaseAuth } from "../context/AuthContext";

import {
  Card,
  Col,
  Row,
  Typography
} from "antd";

import '../assets/css/statisticSimple.css'


const StSimple = (props) => {
  const { t } = useTranslation();
  const { Title, Text } = Typography;

  const [user] = useState(useFirebaseAuth())
  const [dayStat, setDayStat] = useState()
  const [monthStat, setMonthStat] = useState()
  const [yearStat, setYearStat] = useState()
  const [lastWeekStat, setLastWeekStat] = useState()
  const [km, setKm] = useState()
  const [numUsers, setNumUsers] = useState()

  useEffect(()=> {
    const d = new Date(Date.now()-3600*24*1000);
    const day = `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}`;
/*     let m = null;
      const mms = JSON.parse(window.localStorage.getItem(`day_${day}`));
      let foundy = false;
      const dat = new Date(Date.now() - 3600*24*1000);
      const yesterdaImTomorrowEvening = `${dat.getFullYear()}-${(dat.getMonth()+1).toString().padStart(2, '0')}-${(dat.getDate()).toString().padStart(2, '0')}`;
      for(var i = 0; !foundy && i < mms.length; i++) {
        m = mms[i];
        foundy = yesterdaImTomorrowEvening == m.date;
    } */
    setDayStat(JSON.parse(window.localStorage.getItem(`day_${day}`)));
    setMonthStat(JSON.parse(window.localStorage.getItem(`month_${day}`)));
    setYearStat(JSON.parse(window.localStorage.getItem(`year_${day}`)));
    setLastWeekStat(JSON.parse(window.localStorage.getItem(`lastWeek_${day}`)));
  }, [props.dataReady])
  
  useEffect(()=> {
    // Calculamos los kms 
    let kms = [];
    let usr = [];
    if(dayStat) { 
      let d = null;
      let foundy = false;
      const dat = new Date(Date.now() - 3600*24*1000);
      const yesterdaImTomorrowEvening = `${dat.getFullYear()}-${(dat.getMonth()+1).toString().padStart(2, '0')}-${(dat.getDate()).toString().padStart(2, '0')}`;
      for(var i = 0; !foundy && i < dayStat.length; i++) {
        d = dayStat[i];
        foundy = yesterdaImTomorrowEvening == dayStat[i].date;
      }
      parseData('YESTERDAY', '', [d], kms, usr); 
    }
    if(monthStat) { 
      let m = null;
      let foundy = false;
      for(var i = 0; !foundy && i < monthStat.length; i++) {
        m = monthStat[i]
        foundy = new Date().getMonth() == new Date(monthStat[i].date).getMonth();
      }
      parseData('MONTH', 'bgred', [m], kms, usr); 
    }
    if(yearStat) { parseData('YEAR', 'bggrey', yearStat, kms, usr); }
    if(lastWeekStat) { parseData('LAST7D', 'bggreen', lastWeekStat, kms, usr); }
 
    setKm(kms)
    setNumUsers(usr)
  }, [dayStat, monthStat, yearStat, lastWeekStat]);

  const parseData = (title, iclass, data, kms, usr) => {
    let dS = typeof data == 'string' ? JSON.parse(data) : data;
    dS = dS.find(element => element.service == user.service)
    kms.push({ title, value: formatValue(dS?.totalKM), icon:  <RocketOutlined />, bnb: 'bnb2', iclass, units: setUnit(dS?.totalKM)})
    usr.push({ title, value: formatValue(dS?.numUsers), icon:  <TeamOutlined />, bnb: 'bnb2', iclass, units: setUnit(dS?.numUsers) })
    return;
  }

  const formatValue = (v) => {
    let j = v;
    let i = 1;
    do {
      j = v/i;
      i = i*1000;
    } while(v/i > 1);
    return (j.toString().match(/[0-9]*.[0-9]?/)[0] );
  }

  const setUnit = (v) => {
    const units = ['', 'K', 'M', 'B', 'T']
    let j = 0;
    let i = 1000;
    while(v/i > 1) {
      i = i*1000;
      j++;
    }
    return (units[j]);
  }

  const resumeLine = (tt, o) => {
    return (
      <div className="layout-content simple-statics-wrapper">
        {/* Pastillas resumen superiores */}
          <h1>{t(tt)}</h1>
          { o && o?.length == 4 ?
            <Row className="rowgap-vbox" gutter={[24, 0]}>
              {o.map((c, index) => (
                <Col
                  key={`${index}_sts`}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={6}
                  xl={6}
                  className="mb-24"
                >
                  <Card bordered={false} className="criclebox ">
                    <div className="number">
                      <Row align="middle" gutter={[24, 0]} >
                          <>
                            <Col xs={18} >
                              <span>{t(c.title)}</span>
                              <Title level={3}> {c.value} {c.units ? t(c.units) : ''}</Title> 
                              {/* <Title level={3}> {c.value} </Title> */}
                            </Col>
                            <Col xs={6} style={{paddingLeft: "0px !important"}}>
                              <div className={"icon-box " + c.iclass}>{c.icon}</div>
                            </Col>
                          </>
                      </Row>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>       
          :
            <Row className="rowgap-vbox" gutter={[24, 0]}>
              <Col xs={24} style={{width:'100%', display:'flex', justifyContent:'center'}}>
                <Spin size="large" />
              </Col>
            </Row>
          }
      </div>
    )
  } 

    return (
      
      <>
        {km?.length > 0 && resumeLine('TOTAL_KM', km)}
        {numUsers?.length > 0 && resumeLine('NUM_USERS', numUsers)}
      </>
    );
}

export default StSimple;
