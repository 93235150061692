import React, { useEffect, useState } from 'react';

import { Layout, Button, Row, Col, Form, InputNumber, DatePicker, Select, Input, Spin } from 'antd';
// import Tooltip from 'antd/es/tooltip';
// import { InfoCircleOutlined }  from "@ant-design/icons";

import moment from 'moment';

// import MyMenu from '../components/Menu';
// import MyHeader from '../components/Header';

import { useFirebaseAuth } from "../context/AuthContext";
import { getDocu, getDocus, addToArray, setDocu, getHookB, updateDocu } from '../context/firebaseConfig';

import { useTranslation } from 'react-i18next';
import SpeedsTable from '../components/Config/SpeedsTable';
import AccForm from '../components/Config/Accelerations';
import CommonsProps from '../components/Config/CommonProps';
import Touchs from '../components/Config/Touchs';
import Curves from '../components/Config/Curves';
import SimulationResults from '../components/SimulationResults';

import '../assets/css/speedsTable.css'
import '../assets/css/simulator.css'
import ModalSimCreated from '../components/ModalSimCreated';
import { useForm } from 'antd/es/form/Form';


const { TextArea } = Input;

const Simulator = () => {
    const { t } = useTranslation();
    const [speedsTable, setSpeedsTable] = useState(null);
    const [touchs, setTouchs] = useState();
    const [acceleration, setAcceleration] = useState();
    const [deceleration, setDeceleration] = useState();
    const [serviceScoring, setServiceScoring] = useState();
    const [scoring, setScoring] = useState();
    const [defaultScoring, setDefaultScoring] = useState();
    const [minSpeedToCalculateAcc, setMinSpeedToCalculateAcc] = useState();
    const [curves, setCurves] = useState();
    const [btnClass, setBtnClass] = useState('disabled');
    const [formClass, setFormClass] = useState('');
    const [simulations, setSimulations] = useState();
    const [user] = useState(useFirebaseAuth());
    const [form] = useForm();
    const TODAY = moment().format('YYYY-MM-DD')
    const [simsin, setSimSin] = useState(null);
    const [selected, setSelected] = useState();
    const [results0, setResults0] = useState();
    const [resultsN, setResultsN] = useState();
    const [hooks, setHooks] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [docId, setDocId] = useState();
    const [modal, setModal] = useState({open: false})

    useEffect(() => {
        const petete = (async() => {
            setScoring(await getDefault());
            setServiceScoring(await getDefault());
            setSimulations(await getSimulations());
        });
        petete();
    },[])

    useEffect(() => {
        if(scoring) {
            setTouchs(scoring?.touchs);
            setAcceleration(scoring?.acceleration);
            setDeceleration(scoring?.deceleration);
            setMinSpeedToCalculateAcc(scoring?.minSpeedToCalculateAcc);
            setCurves(scoring?.curves)
            setSpeedsTable(scoring?.speeds);
        }
    }, [scoring]);

    useEffect(() => {
        // Resteamos el hook si lo hubiera
        reset();
        // Aqui vamos a recargar el scoring con los valores del seleccionado
        if(selected?.idx >= 0) {
            // Creamos o recuperaos el hook de 0
            let res = existsResults(0, selected.idx === 0)
            if(res != null) setResults0(res)
            if(selected.idx > 0) { 
                res = existsResults(selected.idx, selected.idx > 0)
                if(res != null) setResultsN(res)
            }
        }
        
    }, [selected]);

    const existsResults = (idx, setScore) => {
        const ss = simulations.find(ele => ele.name === selected.name);
        const runtime = ss?.runtimes[idx];
        // El score que se muestra es el del elemento seleccionado cuando es 1 el de 0 hay que 
        if(setScore) {
            setScoring(runtime?.scoreRules); 
            form.setFieldsValue({
                'num': ss.limit,
                'des': ss.description,
                'dat': moment(new Date(ss.timestamp), 'YYYY-MM-DD'),
                'nam': ss.name
            });
        }
        const res = ss?.runtimes[idx].results;
        if(Object.keys(res)?.length > 0) {
            return res;
        } else if(hooks.findIndex(ele=> ele === selected.name) < 0) {
            let nh = [...hooks];
            nh.push(selected.name);
            setHooks(nh);
            getHookB({coll: 'simulations', id: selected.name, service: user.service},
                (snap) => {
                    snap.docChanges().forEach(({ doc, type }) => {
                        if(doc.data().name === selected.name) {
                            console.log('DEBUG', type, doc.data())
                            const d = doc.data()   
                            const runtime = d?.runtimes[selected.idx]
                            if(runtime?.status === 'OK') {
                                if(selected.idx === 0) { setResults0(runtime.results); }
                                else if(selected.idx > 0) { setResultsN(runtime.results); }
                            }
                        }
                    })
                },
                (error) => console.log(`kk de la vaca, ${error}`)
            )
            return null;
        }
    }

    const reset = () => {
        form.setFieldsValue({
            'num': 1000,
            'des': '',
            'dat': null, //moment(new Date(), 'YYYY-MM-DD'),
            'nam': ''
        });
        setResults0(null);
        setResultsN(null);
        setScoring(serviceScoring); 
    }

    const getDefault = async () => {
        const defscr = await getDocu({ coll: 'config', docu: 'DEFAULT'})
        setDefaultScoring(defscr);
        const scr = await getDocu({ coll: 'config', docu: user.service.toUpperCase() }); //window.localStorage.getItem('serviceID')})
        return(scr || defscr);
    }

    const getSimulations = async () => {
        const sims = await getDocus({ coll: 'simulations', service: user.service})
        return sims;
    }

    const speedsCallback = (vs, k, m) => {
        let scr = scoring;
        let nee = scr.speeds.findIndex((ele) => ele.limit === k);
        if(nee < 0) {
            console.log(`Error índice no válido ${nee}`);
            return;
        }
        scr.speeds[nee][m] = vs;
        setBtnClass('')
        setScoring(scr);
    }

    const accelerationCallback = (a) => {
        let nsc = scoring;
        nsc.acceleration = a;
        setBtnClass('')
        setScoring(nsc);
    }

    const decelerationCallback = (d) => {
        let nsc = scoring;
        nsc.deceleration = d;
        setBtnClass('')
        setScoring(nsc);
    }

    const commonsCallback = (v, t) => {
        let nsc = scoring;
        nsc[t] = v;
        setBtnClass('')
        setScoring(nsc);        
    }

    const touchsCallback = (v, t) => {
        let nsc = scoring;
        nsc.touchs = v;
        setBtnClass('')
        setScoring(nsc);
    }

    const curvesCallback = (v, t) => {
        let nsc = scoring;
        nsc.curves = v;
        setBtnClass('')
        setScoring(nsc);
    }

    const simulate = async (e) => {
        setBtnClass('disabled')
        const d =  e?.dat?.format('YYYY-MM-DD');
        const id = new Date(`${d} 23:59:59`).getTime()
        const docId = `${user.service}_${e?.dat?.format('YYYYMMDD')}`;
        setDocId(docId);
        const runId = +new Date();
        const newPlay = {
            date : d,
            name : e.nam || d,
            num : e.num,
            description : e.des || '',
            rules : scoring,
            runtime : runId
        }
        if(newPlay !== simsin) {
            let itsOk = true;
            setSimSin(newPlay);
            // comprobaoms si el documento existe getDocu de 'd'
            const docuData = await getDocu({coll: 'simulations', docu: docId})
            const isNew = docuData == null;
            if(isNew) {
                // Recuperamos el score actual y generamos el runtime 0
                const docu = {
                    endDate:  `${d} 23:59:59`, // de aquí hacia atrás se recuperan los X viajes, formato storing
                    timestamp:  id, // endDate en formato timestamp es el ID
                    service: user.service,
                    name: e.nam || d, // Opcional si no lo asigna se le pone el endDate
                    description: e.des || "",
                    limit: e.num || 1000
                }
                itsOk = await setDocu({coll: 'simulations', docu: docId, data: docu})
            } else {
                itsOk = await updateDocu({coll: 'simulations', docu: docId, data: { emailSent: false}})
            }
            if(itsOk) {
                if(isNew) {                   
                    // Runtime 0 on el score original
                    const run0 = { // De la solicitud
                        id: 0,
                        date: moment().format('YYYY-MM-DD'), // fecha de la petición del runtime en formato amigable
                        status: 'fase0',
                        reason: '', // en el caso de KO
                        scoreRules: await getDefault() , // Objeto reglas que se deben de aplicar
                        results: {} // resultados obtenidos
    
                    }
                    if(!(await addToArray({coll: 'simulations', docu: docId, arr: 'runtimes', data: run0}))) {
                        alert('ohoh,problemas')
                        return;
                    }
                }
                const newRun = { // De la solicitud
                    id: runId,
                    date: moment().format('YYYY-MM-DD'), // fecha de la petición del runtime en formato amigable
                    status: 'fase0',
                    reason: '', // en el caso de KO
                    scoreRules: scoring , // Objeto reglas que se deben de aplicar
                    results: {} // resultados obtenidos

                }
                if(await addToArray({coll: 'simulations', docu: docId, arr: 'runtimes', data: newRun})) {
                    setSimulations(await getSimulations());
                    loadSelect();
                    let m = {...modal};
                    m.open = true;
                    m.docId = docId;
                    m.message = t('SIMULATION_CREATED')
                    setModal(m)
                } else {
                    let m = {...modal};
                    m.open = true;
                    m.docId = null;
                    m.message = t('SIMULATION_ERROR_CREATING')
                    setModal(m)
                }
            } else {
                let m = {...modal};
                m.open = true;
                m.docId = null;
                m.message = t('SIMULATION_ERROR_CREATING')
                setModal(m)
            }
            // await callApi()
        } else {
            alert('No se ha  modificado nada')
        }
    }

    const loadSelect = () => {
        let ssss = [];
        simulations?.map((ele) => {return ssss = [...ssss, ...(loadRuntimes(ele.runtimes, ele.name || ele.date))]});
        return(
            <Select className='w100' key={`simSel`} onChange={(e) => { 
                console.log('piso');
                console.log(e);
                return e ? setSelected(JSON.parse(e)) : ''
            }}>
                <Select.Option key='0' value={`{"name": "none", "idx": -1}`}> </Select.Option>
                {ssss}
            </Select>
        )
    }

    const loadRuntimes = (runs, papito) => {
        const ssss = runs?.map((ele, idx) => {return <Select.Option key={`${papito}_${idx}`} value={`{"name": "${papito}", "idx": ${idx}}`}>{`${papito} (${idx})`}</Select.Option>});
        return(ssss)
    }

    const getRuntime = (idx) => {
        const ss = simulations.find(ele => ele.name === selected.name);
        return ss?.runtimes[idx];
    }
    
    const parseTime = (t) => {
        return (moment(Math.trunc(t)).format('HH:mm'));
    }

    if(!scoring) return (<></>)
    else { return (
        <React.Fragment>
            <div style={{marginTop: "10px", margin: '0 auto', width: "100%"}}>
                <Row gutter={[0, 16]}>
                    <Col span={22} offset={1} >
                        <SpeedsTable 
                            expanded="false"
                            speedsTable={scoring?.speeds} 
                            spCb={speedsCallback} 
                        />
                        <CommonsProps minSpeedToCalculateAcc={minSpeedToCalculateAcc} cpCb={commonsCallback} />
                        <AccForm type='brakes' acceleration={deceleration} acCb={decelerationCallback} def={defaultScoring.deceleration} />
                        <AccForm type='accelerations' acceleration={acceleration} acCb={accelerationCallback} def={defaultScoring.acceleration} />
                        <Touchs touchs={touchs} touchCb={touchsCallback} def={defaultScoring} />
                        <Curves curves={curves} cvCb={curvesCallback} /> 
                        
                    </Col>
                </Row>

                <Row gutter={[0, 0]} style={{ marginTop: "10px" }}>
                    <Col span={22} offset={1} >
                        <h1>{t('SIMULATOR_CONFIG')}</h1>
                        <Form style={{ display: "flex", flexWrap: "wrap"}} onFinish={simulate} disabled={formClass} form={form}>
                            <Col span={14} style={{ display: "flex", flexWrap: 'wrap' }}>
                                <Col span={10}>
                                    <Form.Item 
                                        label={t('LABEL_NUMBER_TRAVELS')} 
                                        name='num' initialValue={1000} 
                                        rules={[{ required: true, message: t('TRAVELS_NUM_REQUIRED') }]} >
                                {/*     <Tooltip title={t('SPEEDS_ACTIVATIONTIME_TOOLTIP')}>
                                        <InfoCircleOutlined className='info-icon' />
                                        </Tooltip> */}
                                    <InputNumber className='w100' max={1000} min={0} disabled={selected?.idx >= 0}/></Form.Item> 
                                </Col>
                                <Col span={10} offset={1}>
                                    <Form.Item 
                                        label={t('LABEL_DATE')}
                                        name='dat' 
                                        // // initialValue={moment(TODAY, 'YYYY-MM-DD')} 
                                        rules={[{ required: true, message: t('SIMULATION_DATE_REQUIRED') }]} 
                                    >
                                        <DatePicker 
                                            format={'YYYY-MM-DD'} 
                                            className='w100' 
                                            disabled={selected?.idx >= 0}
                                            // defaultValue={dayjs('2015-01-01', 'YYYY-MM-DD')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={21}>
                                    <Form.Item span={12} label={t('LABEL_SELECT_SIMULATOR_CONFIG')} name='sel'>
                                        {/*simulations?.length > 0 ? loadSelect(): ''*/}
                                        {loadSelect()}
                                    </Form.Item>
                                </Col>
                            </Col>
                            <Col span={10} >
                                <Form.Item label={t('LABEL_DESCRIPTION')} name='des'>
                                    <TextArea rows={4} placeholder="" maxLength={256}  disabled={selected?.idx >= 0}/>
                                </Form.Item>
                            </Col>
                            <Col span={24} style={{ textAlign: "center" }} >
                                <Form.Item ><Button type='primary' htmlType="submit" disabled={btnClass} >{t('BUTTON_RUN')}</Button></Form.Item>
                            </Col>
                        </Form>
                    </Col> 
                </Row>
                { selected?.idx >= 0 ? 
                <Row gutter={[0, 0]}  >
                    { results0 || resultsN ? 
                        <>
                            <Col span={23}>
                                <span className='titleLine'>{ selected.name } ({ results0.nTravels } { t('NUM_TRAVELS')})</span>
                            </Col>
                            <Row style={{width: "96%"}} gutter={[10, 10]}>
                                <Col className='resultsMiniBox mr'>
                                    <div className='flexCol'>
                                        <span className='labelLine ml'>{t('DISTANCE_RESULT')}</span>
                                        <span className='valueLine'>{`${(results0.distance/results0.nTravels).toFixed(2)} Km/${t('PER_TRAVEL')}`}</span>
                                        <span className='valueLine'>{`${results0.distance} ${t('TOTAL')}`}</span>
                                    </div>
                                </Col>
                                <Col className='resultsMiniBox mr'>
                                    <div className='flexCol'>
                                        <span className='labelLine ml'>{t('DURATION_RESULT')}</span>
                                        <span className='valueLine'>{`${parseTime(results0.duration/results0.nTravels)} ${t('PER_TRAVEL')} `}</span>
                                        <span className='valueLine'>{`${parseTime(results0.duration)} ${t('TOTAL')}`}</span>
                                    </div>
                                </Col>
                                <Col className='resultsMiniBox mr'>
                                    <div className='flexCol'>
                                        <span className='labelLine ml'>{t('AVERAGESPEED_RESULT')}</span>
                                        <span className='valueLine'>{`${(results0.averageSpeed/results0.nTravels).toFixed(2)}`}</span>
                                    </div>
                                </Col>
                                <Col className='resultsMiniBox'>
                                    <div className='flexCol'>
                                        <span className='labelLine ml'>{t('MAXSPEED_RESULT')}</span>
                                        <span className='valueLine'>{`${(results0.maxSpeed/results0.nTravels).toFixed(2)}`}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Col span={23} className='resultsBox'>
                                {/* {results0 && paintResults(results0)} */}
                                { results0 != null && Object.keys(results0).length > 0 ? 
                                    <SimulationResults name={selected.name} service={user.service} results={results0} idx='0' runtime={getRuntime(0)} />
                                    :
                                    <Spin size="large" />
                                }
                            </Col>
                            { selected?.idx > 0 ? 
                                <Col span={23} className={`resultsBox ${selected?.idx >= 0 ? ' resultsBoxB' : ''}`}>
                                    { resultsN != null && Object.keys(resultsN).length > 0 ? 
                                        <SimulationResults {...{name: selected.name, service: user.service, results: resultsN, idx: selected.idx, runtime: getRuntime(selected.idx)}} />
                                        :
                                        <div style={{width: "100%", textAlign: "center" }}>
                                            <Spin size="large" />
                                        </div>
                                    }
                                </Col>
                            :' '}
                        </>
                    :''}
                </Row>
                : '' }
                <ModalSimCreated  callbackModal={setModal} {...modal} />
            </div>
        </React.Fragment>
    )}
}

export default Simulator;