import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import { EyeOutlined, EyeInvisibleOutlined, DeleteOutlined } from '@ant-design/icons';

import { deleteDocu, getData, getHook } from "../context/firebaseConfig";
import { useFirebaseAuth } from "../context/AuthContext";

import '../assets/css/main.css'

const { confirm } = Modal;


const AlertsTemplate = (props) => {
    const [type] = useState(props.type);
    // const [label] = useState(type==='alerts' ? 'LABEL_ALERTS' : type === 'cancelled' ? 'LABEL_CANCELLED' : 'LABEL_CLOSED');
    const [rowData, setRowData] = useState([]);
    const [lastRow, setLastRow] = useState([]);
    const [order] = useState('alertId');
    const [lim] = useState(10);
    const [start, setStart] = useState(null);
    const [more, setGetMore] = useState(true)
    const nav = useNavigate();
    const { t } = useTranslation();
    const [user] = useState(useFirebaseAuth());

    const parseDate = (mode, r) => {
        /* const isoStr = new Date(r).toISOString();
        // Obtener la hora local de Madrid: ( Habria que coger la hora local del service)
        const dateObj = new Date(r);
        //TODO:DUDA  he modificado este template para que se vea la hora correcta ntra local, segun timezone, pero me surge la duda de si el localString tb lo metemos en el service pq puede ser "en-US", 'en-GB', 'ko-KR', "ar-EG", "de-DE"
        //https://developer.mozilla.org/es/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
        //https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
        const hours = dateObj.toLocaleString('es-ES', {
        timeZone: user?.configs?.timezone || 'Europe/Madrid',
        hour12: false, // Usar formato de 24 horas
        hour: '2-digit', // Obtener las horas
        minute: '2-digit', // Obtener los minutos
        second: '2-digit' // Obtener los segundos
        }); 
        // la hora mostrada no se corresponde conla hora local. Modificamos esta linea para que muestre la hora segun el timezone del servicio.
        // return mode ? isoStr.substring(0, isoStr.indexOf('T')) : isoStr.substring(isoStr.indexOf('T')+1, isoStr.indexOf('.'));
        // return mode ? dateObj.toLocaleDateString('es-ES', { timeZone: 'Europe/Madrid' }) : hours;
        return mode ? isoStr.substring(0, isoStr.indexOf('T')) : hours; */

        const dateObj = new Date(r);
        const y = dateObj.getFullYear();
        const m = String(dateObj.getMonth() + 1).padStart(2, '0'); //+1 pq meses van de 0 a 11
        const d = String(dateObj.getDate()).padStart(2, '0');
        const hh = String(dateObj.getHours()).padStart(2, '0');
        const mm = String(dateObj.getMinutes()).padStart(2, '0');
        const ss = String(dateObj.getSeconds()).padStart(2, '0');
        return mode ? `${y}-${m}-${d}` :  `${hh}:${mm}:${ss}`;
    }

    const CustomActions = ({row, idx}) => (
        <div>
            <Button onClick={() => nav('/watch', { state: { data:row, type: type, petet:'si'}})}>
                {t('BUTTON_VIEW')}
                {row.viewed ? <EyeOutlined /> : <EyeInvisibleOutlined /> }
            </Button>
            {type !== 'alerts' &&
            <Button 
                onClick={() => deleteRow(row)}
                style={{marginLeft: '5px'}}
            >
                {<DeleteOutlined />}
            </Button>
    }
        </div>
    );

    const deleteRow = async (row) => {
        confirm({
            async onOk() { 
                await deleteDocu({coll: type, docu: `${row.alertId}${row.phone}`});
                setRowData([]);
                setStart(null);
                setGetMore(true);
                return;
            },
            onCancel() { return; },
            content: t('CONFIRM_DELETE_TEXT'),
            cancelText: t('BUTTON_CANCEL'),
            okText: t('BUTTON_DELETE_OK')
        })
        // console.log(row);
        // await deleteDocu({coll: type, docu: `${row.alertId}${row.phone}`});
    }

    const [columnDefs] = useState([
        {selector: row => parseDate(true, Number(row.alertId)), name: t('LABEL_DATE')},
        {selector: row => parseDate(false, Number(row.alertId)), name: t('LABEL_TIME')},
        {selector: row => row.phone, name: t('LABEL_USER')},
        {selector: row => row.alertId, name:'ID'},
        /* {name: t('LABEL_ACTIONS'), button: true, cell: row => <Button onClick={() => alert(JSON.stringify(row))}>{t('BUTTON_VIEW')}</Button>}, */
        {name: t('LABEL_ACTIONS'), cell: (row, index) => <CustomActions row={row} idx={index} />}
    ])

    const customStyles = 
        {
        	rows: {
                style: {
                    backgroundColor: 'rgba(63, 195, 128, 0.9)',
                    color: 'white',
                        '&:hover': {
                            cursor: 'pointer',
                    },
                },
            },
        }
        
    useEffect(() => {
        if(more) {
            const getRows = async () => {
                getData({coll: type, order: order, lim: lim, start: start, service: user.service})
                .then(d => {
                    const jj = d.docs.map(s => { 
                        let data = s.data();
                        data.defaultExpanded=false;
                        return data;
                    });
                    const last =  d.docs[d.docs.length-1]
                    setStart(last);
                    const rows = [...rowData, ...jj];
                    setRowData(rows);
                })
                .catch(error => {
                    console.error("Error fetching data: ", error);
                });
            }
            getRows()
            setGetMore(!more)
        }
    }, [more, lim, order, start, type, rowData]);

    useEffect(() => {
        const unsubscribe = getHook({coll: type, order: 'alertId', lim: 1, service: user.service},
            (querySnapshot) => {
                const lst = querySnapshot.docs.map(docSnapshot => docSnapshot.data());
                setLastRow(lst);
            },
            (error) => {console.log(error); alert('kk de la vaca');}
        );
        return unsubscribe;
    }, [setLastRow, type]);
    
    return (
        <React.Fragment>
            <div  style={{overflow:'auto', width: '80%', margin: '0 auto', paddingTop: 20, display: 'flex', flexDirection: 'column'}}>
                { lastRow.length > 0 &&
                    <DataTable
                        columns={columnDefs}
                        data={lastRow}
                        customStyles={customStyles}
                    />
                } 
                <DataTable
                    columns={columnDefs}
                    data={rowData}
                    noTableHead={lastRow.length > 0 }
                    /* expandableRows
                    expandableRowExpanded={row => row.defaultExpanded}
                    expandableRowsComponent={ExpandedComponent} */
                />

                <Button style={{marginTop: 10}} onClick={() => setGetMore(true)}> + </Button>
            </div>
        </React.Fragment>
    );
}

export default AlertsTemplate;