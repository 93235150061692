import React, { useState, useEffect } from "react";

import { Form, InputNumber, Collapse } from "antd";

import { t } from 'i18next';

const { Panel } = Collapse;

const CurvesForm = (props) => {
    const [curves, setCurves] = useState() 
    const [minCurveDegrees, setMinCurveDegrees] = useState();
    const [penalization, setPenalization] = useState()
    const [coefficient, setCoefficient] = useState();

    useEffect(() => {
        if(props.curves && Object.keys(props.curves).length > 0) {
            setCurves(props.curves)
            setCoefficient(props.curves.coefficient*100-100)
            setPenalization(props.curves.penalization)
            setMinCurveDegrees(props.curves.minCurveDegrees)
        }
    }, [props.curves])

    const set = (e, t) => {
        let na = curves;
        const mode = t == 1 ? "penalization": t==0? "coefficient" : "minCurveDegrees"
        na[mode] = e;
        setCurves(na);
        if(t==0) setCoefficient(e);
        else if (t==1) setPenalization(e);
        else if (t==2) setMinCurveDegrees(e);
        props.cvCb({
            coefficient: (t==0 ? e : coefficient)/100+1, //coefficient*100-100;
            penalization: t==1 ? e : penalization,
            minCurveDegrees: t==2 ? e : minCurveDegrees
        })
    }

    const setCard = () => {
       if(!curves) return(<></>)
        return(
            
            <Collapse>
                <Panel header={t('LABEL_CURVES')}>
                    <Form name={props.type} onFinish={() => {console.log('')}} >
                        <Form.Item label={t('LABEL_MIN_DEGRESS')}>
                            <InputNumber 
                            name='minCurveDegrees'
                            onChange={(e) => { set(e, 2)}} 
                            defaultValue={minCurveDegrees}
                            value={minCurveDegrees} 
                            min={0} max={360}/>
                        </Form.Item>
                        <Form.Item label={t('LABEL_COEFFICIENT')} help={t('LABEL_CURVES_K') }>
                            <InputNumber 
                            name='coefficient'
                            onChange={(e) => { set(e, 0)}} 
                            defaultValue={coefficient}
                            value={coefficient}
                            />
                        </Form.Item>
                        <Form.Item label={t('LABEL_PENALIZATION')}>
                            <InputNumber 
                            name='penalization'
                            onChange={(e) => { set(e, 1)}} 
                            step={0.1}
                            defaultValue={penalization}
                            value={penalization}
                            min={0} max={100} />
                        </Form.Item>
                    </Form>
                </Panel>
            </Collapse>
        )
    }

    return(
        setCard()
    )
}

export default CurvesForm;