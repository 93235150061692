import React, { useEffect, useState } from "react";
import { Card, Button, Modal, Select, Form, Spin } from "antd";

import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useFirebaseAuth } from "../context/AuthContext";
import { useTranslation } from "react-i18next";

import { 
    CloseCircleFilled,
    InfoCircleFilled,
    InfoCircleOutlined,
    DashboardFilled,
    DashboardOutlined,
    AreaChartOutlined,
    LineChartOutlined,
    AudioFilled,
    AudioOutlined,
    PlusCircleFilled,
    MinusCircleOutlined
} from  "@ant-design/icons";

import { getWatchingHook, getWatchingReport, getWatchingImpacts, closeAlert, updateDocu } from "../context/firebaseConfig";

import { useLocation, useNavigate } from "react-router-dom";

import '../assets/css/watchAlert.css'
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

const containerStyle = {
    width: '100%',
    height: '90vh'
  };

  //TODO: Faltan detalles en esta Vista de detalle de alerta: no se ven las graficas, las coordenadas no son copiables como en el antiguo panel. Y los audios en el antiguo salia un btn de play para reproducirlos directamette con la la hora de grabacion del mismo. Comparar con distintos proyectos lo que sale en el panel de Vue e ir anotandolo
  
const WatchAlert = (props) => {
    const [user] = useState(useFirebaseAuth());
    const [data] = useState(useLocation().state.data);
    const {t} = useTranslation();
    const type = useLocation().state.type;
    const nav = useNavigate();
    const [form] = Form.useForm();

    const [modalState, setModalState] = useState(false);
    
    const [locations, setLocations] = useState(useLocation().state.data.locations);
    const [reported, setReported] = useState([])
    const [markers, setMarks] = useState([])
    
    const {alertId, phone } = useLocation().state.data;
    const [center] = useState({lat: data.latitude || data.lat, lng: data.longitude || data.long || data.lng})
    
    const [canceled, setCanceled] = useState(false);
    
    const [resume, setResume] = useState(false);

    const [report, setReport] = useState(null);
    const [impactData, setImpactData] = useState(['','']);
    
    const [impacts, setImpacts] = useState(false);
    const [chart, setChart] = useState(false);
    const [audios, setAudios] = useState(false);
    const [showAudios, setShowAudios] = useState(useLocation().state.data.audioUrls?.length > 0);
    const [arrAudios, setArrAudios] = useState([]);
    const [chartRendered, setChartRendered] = useState(null);
    const [chartSpeeds, setChartSpeed] = useState(null);

    const [closingAlert, setClosingAlert] = useState(false);
    
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDn38IJ8ElyvBI0NJp9Psf0KJeW_ajGsk4"
    })
    const set = (b, l) => {
        if(b) setLocations(l);
    }

    useEffect(() => {
        if(type === 'alerts') {
            const unsubscribe = getWatchingHook({coll: type, alertId: `${alertId}${phone}`},
                (querySnapshot) => {
                    const d = querySnapshot.data()
                    const lls = d.locations;
                    setCanceled(d.status !== 0)
                    setLocations(lls);
                    setShowAudios(d?.audioURLs?.length > 0 || d?.audioUrls?.length > 0);
                    setArrAudios(d?.audioURLs || d?.audioUrls)
                },
                (error) => alert('kk de la vaca')
            );
            return unsubscribe;
        } 
    }, [setLocations]);

    useEffect(() => {
        async function readReport() {
            const querySnapshot = await getWatchingReport({alertId: `${alertId}${phone}`})
            const data = querySnapshot;
            setReport(data);
            if(data?.report?.locations)  {
                setReported(data?.report?.locations);
                const speeds = [['',''],...data.report.locations.map(l => ['',l.speed])];
                
                setChartSpeed(<google-chart 
                    type="Line"
                    options={{
                        legend: "none"
                    }}
                    data={`${JSON.stringify(speeds)}`} 
                ></google-chart>);
            }
            await updateDocu({coll: type, docu: `${alertId}${phone}`, data: { viewed: true }});
        } 
        readReport();
        return;
    }, []);
 
    useEffect(()=> {
        async function readImpacts() {
            const querySnapshot = await getWatchingImpacts({hasAccelerations: report?.hasAccelerations, alertId: `${alertId}${phone}`, impacts: chartRendered != null})
            const data = querySnapshot;
            if(data?.length > 0) {
                data.unshift([{type: 'string', label:''},''])
                setImpactData(data);
                setChartRendered(<google-chart 
                    type="Line"
                    data={`${JSON.stringify(data)}`} 
                    options={{
                        legend: "none"
                    }}
                ></google-chart>);
            }
        }
        readImpacts();
    }, [report?.hasAccelerations]);
     
    useEffect(()=> {
        setMarks([...reported, ...locations || []]);
        setResume(true);
        setAudios(true);
        setChart(true);
        setImpacts(true);

    }, [reported, locations, setLocations])

    const handleSubmit = async (e) => {
        // reason, description
        setClosingAlert(true);
        const ret = await closeAlert({ 
            id:`${alertId}${phone}`, 
            status: e.reason || 103, 
            comment: e.description || '',
            tk: user?.accessToken,
            service: user?.service
        });
        form.resetFields();
        setClosingAlert(false);
        nav(-1);
    }

    const getDot = (l) => {
        // console.log(l)
        const dot = !l.data ? (l.crash ? 'red' : 'yellow' ): 'blue';
        //onsole.log(!l.data && l.crash, dot)
        return dot;
    }

    const parseDate = (r) => {
        const dateObj = new Date(r);
        const y = dateObj.getFullYear();
        const m = String(dateObj.getMonth() + 1).padStart(2, '0'); //+1 pq meses van de 0 a 11
        const d = String(dateObj.getDate()).padStart(2, '0');
        const hh = String(dateObj.getHours()).padStart(2, '0');
        const mm = String(dateObj.getMinutes()).padStart(2, '0');
        const ss = String(dateObj.getSeconds()).padStart(2, '0');
    
        const fd = `${y}-${m}-${d}, ${hh}:${mm}:${ss}`;
        return fd;
    }

    return (
        <React.Fragment>
            {isLoaded &&
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={15}
                    //onLoad={onLoad}
                    //onUnmount={onUnmount}
                    >
                    {   //si no hay markers tenemos que mostrar la posición para el SOS
                        markers.length < 1 ?   
                        (<Marker 
                            position={{
                                lat:data.latitude || data.lat || data.data?.latitude || data.data?.lat, 
                                lng:data.longitude || data.long || data.lng || data.data?.longitude || data.data?.long || data.data?.lng
                            }}
                            key={'markIni'}
                            gmpClickable={false}
                        />) : (<></>)
                    }
                    {markers.map((l, i) => (
                        <Marker 
                            position={{
                                lat:l.latitude || l.lat || l.data?.latitude || l.data?.lat , 
                                lng:l.longitude || l.long || l.lng || l.data?.longitude || l.data?.long || l.data?.lng
                            }} 
                            icon={`/assets/img/${getDot(l)}-dot.png`} 
                            key={i}
                        />
                    ))}
                </GoogleMap>
            } 
            {canceled && 
                <div className="blink-wrap" >
                    <div className="blink">
                        <a onClick={() => { nav(-1);}} style={{color:'black'}}><span>Cancelada</span></a>
                    </div>
                </div>
            }
            <div className="cards-wrapper" style={{height:'auto', display:'flex', flexFlow: 'column'}}>
                {resume && 
                    <Card title={t('CARD_TITLE_RESUME')} style={{  borderRadius: '15px', margin: '0 5px 5px 0', overflow:'auto' }} bordered
                        extra={<CloseCircleFilled className="card-close-icon" onClick={() => setResume(!resume)} />} >
                        <p><i>{t('LABEL_PHONE')}:</i> {phone}</p>
                        {/* ESTE LABEL DATE ES EL QUE NOS SALE CON LA HORA MAL Y CORREGIMOS CON LINEA SIGUIENTE */}
                        {/* <p><i>{t('LABEL_DATE')}:</i> {new Date(alertId).toISOString().replace('T', ' - ')}</p> */}
                        <p><i>{t('LABEL_DATE')}:</i> {parseDate(alertId)}</p>
{/* TODO:DUDA Las dos siguientes lineas muestran la misma info, pero de manera distinta ¿Cual dejamos? La primera muestra siempre el Label y el valor de severity o cero. Mientras que la segunda opcion lo que hace es mostrar toda la linea o no en funcion de si hay severity  */}
                        {/* <p><i>{t('LABEL_SEVERITY')}:</i> {report?.severity && report.severity}</p> */}
                        {(report?.severity && <p><i>{t('LABEL_SEVERITY')}:</i> {report.severity}</p> )|| ''} 

                        {report?.noiseLevel && <p><i>{t('LABEL_AMBIENT_SOUND')}:</i> {report.noiseLevel}</p>}
                        <p><i>{t('LABEL_COORDS')}:</i> {data.lat.toFixed(6)} , {data.long.toFixed(6)}</p>
                        {/* Las dos siguientes lineas servirían para informar del motivo de cancelación o el motivo de cierre de dicha alerta. TODO: MEJORA */}
                        {/* {data?.cancelReason && <p><i>{t('LABEL_CANCEL')}:</i> {data.cancelReason}</p> } */}
                        {/* {data?.comment && <p><i>{t('LABEL_CLOSE')}:</i> {data.comment}</p> }  */}

                    </Card>
                }
                {impacts && 
                    <Card title={t('CARD_TITLE_IMPACTS')} style={{ height: 'auto', borderRadius: '15px', margin: '0 5px 5px 0' }}
                        extra={<CloseCircleFilled className="card-close-icon" onClick={() => setImpacts(!impacts)} />} >
                        <div className="impact box" >
                            {chartRendered}
                            <div style={{marginTop: 10}}>
                                <p><span>{t('LABEL_PEAKS')}:</span> {report?.impactPeaks ? report.impactPeaks: 0}</p>
                                {report?.maxG &&  <p><span>{t('LABEL_INTENSITY')}:</span> {report.maxG.toFixed(1)}</p>}
                                <span>{t('LABEL_FLIGHT')}:</span> {report?.flight ? "Si":"No"}<br/> 
                            </div>
                        </div>
                    </Card>
                }
                {chart && 
                    <Card title={t('CARD_TITLE_CHART')} style={{ height: 'auto', borderRadius: '15px', margin: '0 5px 5px 0'  }}
                        extra={<CloseCircleFilled className="card-close-icon" onClick={() => setChart(!chart)} />} >
                        <div className="speedDistance box">
                            {chartSpeeds}
                            <span>{t('LABEL_DISTANCE_BEFORE')}:</span> {report?.preAccidentDistance.toFixed(2)}m.<br/>
                            <span>{t('LABEL_DISTANCE_AFTER')}:</span> {report?.accidentDistance.toFixed(2)}m.<br/>
                            {report?.altitudeImpact && report?.altitudePre && report?.altitudePost &&
                            <span>
                                {t('LABEL_ALT_INI')}= {Number(report.altitudePre).toFixed(0)}m.<br/>
                                {t('LABEL_ALT_IMPACT')}= {Number(report.altitudeImpact).toFixed(0)}m.<br/>
                                {t('LABEL_ALT_END')}= {Number(report.altitudePost).toFixed(0)}m.<br/>
                                {t('LABEL_ALT_DIFF')}= {Number(report.altitudePre-report.altitudePost).toFixed(0)}m.
                            </span>}
                        </div>
                    </Card>
                }
                {showAudios ? audios && 
                    <Card title={t('CARD_TITLE_AUDIOS')} style={{height: 'auto', borderRadius: '15px', margin: '0 5px 5px 0' }}
                        extra={<CloseCircleFilled className="card-close-icon" onClick={() => setAudios(!audios)} />} >

                        {/* TODO:GEMA este bloque hay que recorrer el array de los audios (showAudios o audios, creo que el primero) Y enlugar de mostrar CardContent en p, tendremos que ir generando p por cada elemento del array Con lo siguiente me sale la url. Ver en snoowfy, que sale el icono de play para oirlo directamente + la hora de grabacion del audio. 
                            {data: "https://firebasestorage.googleapis.com/v0/b/snowfy-kaps.appspot.com/o/users%2F%2B34656600300%2Falerts%2F1705421011480%2F07.m4a?alt=mediatoken=300ff8f0-2dc3-4bb2-be16-fe82cf8eb"
                            time: 1705421108964}
                        Tambien no se muestra las graficas en Impactos y en Velocidad y Distancias.*/}
{/* {console.log(arrAudios)} */}
                        {/* {arrAudios.map((audio) => (
                            <p key={audio.id}>{audio.data}</p>
                        ))} */}
                        <p>Card content</p>
                        <p>Card content</p>
                        <p>Card content</p>
                    </Card>
                    :''
                }
            </div>
            <div className="cards-menu-wrapper">                        
                {resume && impacts && chart && audios ? <MinusCircleOutlined className="card-menu-icon" onClick={() => { 
                    setResume(false); setImpacts(false); setChart(false); if(showAudios) setAudios(false) }} /> :
                    <PlusCircleFilled className="card-menu-icon" onClick={() => { 
                        setResume(true); setImpacts(true); setChart(true); if(showAudios) setAudios(true); }} />}
                {resume ?  <InfoCircleFilled className="card-menu-icon" onClick={() => setResume(!resume)} /> :
                    <InfoCircleOutlined className="card-menu-icon" onClick={() => setResume(!resume)} /> }
                {impacts ? <AreaChartOutlined className="card-menu-icon" onClick={() => setImpacts(!impacts)} /> :
                    <LineChartOutlined className="card-menu-icon" onClick={() => setImpacts(!impacts)} /> }
                {chart ? <DashboardFilled className="card-menu-icon" onClick={() => setChart(!chart)} /> :
                    <DashboardOutlined className="card-menu-icon" onClick={() => setChart(!chart)} /> }
                {showAudios ?
                    audios ? <AudioFilled className="card-menu-icon" onClick={() => setAudios(!audios)} /> :
                    <AudioOutlined className="card-menu-icon" onClick={() => setAudios(!audios)} /> 
                    : ''
                }
            </div>   
            <div className="actions-menu-wrapper">  
                { type==='alerts' &&
                    <button className="btnAction btActionCloseAlert" onClick={() => { setModalState(true); }}>{t('BUTTON_CLOSE_ALERT')}</button>
                }
                <button className="btnAction btActionClosePage" onClick={() => { nav(-1); }}>X</button>
            </div>  
            <Modal
                open={modalState}
                title={t('CLOSE_CONFIRM_TEXT')}
                footer={
                    closingAlert ? 
                        [<Spin size="large"/>]
                    :
                        [<Button key="back" onClick={() => {setModalState(false);}}>
                        {t('BUTTON_CANCEL')}
                        </Button>,
                        <Button key="submit" type="primary" onClick={() => form.submit() }>
                        {t('BUTTON_OK')}
                        </Button>]
                }
            >
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                    <Form.Item label={t('LABEL_CAUSE_SELECTOR')} name='reason'>
                        <Select defaultValue="103"  disabled={closingAlert}>
                            <Option value="200">{t('CONFIRMED_COLISION')}</Option>
                            <Option value="103" selected>{t('UNCONFIRMED_COLISION')}</Option>
                            <Option value="102">{t('FAKE_COLISION')}</Option>
                            <Option value="101">{t('FAKE_DATA')}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label={t('LABEL_CLOSE_DESCRIPTION')} name='description'>
                        <TextArea rows={4} disabled={closingAlert}/>
                    </Form.Item>
                </Form>
            </Modal>               
        </React.Fragment>
    )
}

export default WatchAlert;