import { Outlet, Navigate } from 'react-router';
import {useFirebaseAuth} from './context/AuthContext.js';
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout.js';
import MyHeader from './components/Header.js';
import MyMenu from './components/Menu.js';

const ProtectedRoute = () => {
  const a = useFirebaseAuth();
  const page = window.location.pathname.replace(/\//g, '');
  return  a 
  ? 
    <Layout>
      <MyHeader title={page.charAt(0).toUpperCase() + page.slice(1)} />
      <Content style={{display:"flex"}}>
        <MyMenu />
        <Outlet />
      </Content>
    </Layout> 
  : <Navigate to='/login' />;
}

export default ProtectedRoute