import React, {useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Layout } from 'antd'
import { useTranslation } from 'react-i18next';
import { useFirebaseAuth } from "../context/AuthContext";
import { logOut } from "../context/firebaseConfig";
import LanguageSelector from "../components/langSelector/LanguageSelector";

import { PageHeader } from '@ant-design/pro-layout';
import { getPrj } from "../constants/methods";

const MyHeader = (props) => {
    const nav = useNavigate();
    const loc = useLocation();
    const {t} = useTranslation();
    const [user] = useState(useFirebaseAuth());
    const [subtitle] = useState(t(`PAGE_${props.title.toUpperCase()}`))
    
    const title = process.env[`REACT_APP_${getPrj().toUpperCase()}`] || process.env.REACT_APP_TGD;
    return(

        <PageHeader key='myheader'
            style={{ "backgroundColor": "#001529"}}
            ghost='false'
            title={<h1 style={{fontFamily: "Futura", fontSize: '2em', color:"white"}}>{title}</h1>}
            subTitle={<h3 style={{fontFamily:"Futura", color:'red', marginLeft:'1.5em'}} >{subtitle}</h3>}
            extra={[
                <h3 key='h3_2_header' style={{fontFamily:"Futura", color:'red', paddingTop: '0.4em', marginRight: '0.7em'}} >{t('LABEL_SERVICE')}: {user.service}</h3>,
                <h3 key='h3_3_header' style={{fontFamily:"Futura", color:'red', paddingTop: '0.4em', marginRight: '0.7em'}} > {user.email}</h3>,
                user ? 
                    <Button onClick={() => { logOut(); return nav('/login')}}   key="logout_header"
                        type="primary" style={{marginRight: '0.8em'}}>{t('BUTTON_LOGOUT')}</Button> 
                 :
                    loc.pathname !== '/login' ?
                        <Button onClick={() => nav('/login')} key="login_header" 
                            type="primary" style={{marginRight: '0.8em'}}>{t('BUTTON_LOGIN')}</Button> :
                        <div key='no_login_header'></div> ,
                <LanguageSelector key='lgSelector' />,
                <span key='myspn'>{}</span>
            ]}
            
        />
    )
}

export default MyHeader;