import React, { useState, useEffect} from "react";
import { AutoComplete, Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment'

import MyMenu from "../components/Menu";
import MyHeader from "../components/Header";
import StSimple from "../components/statisticSimple";
import StChart from "../components/statisticChart";
import StDonut from "../components/statisticDonut"
import { useFirebaseAuth } from "../context/AuthContext";
import { getStatistics } from "../context/firebaseConfig";
import '../assets/css/main.css'



const { Footer, Content } = Layout;

const Dashboard = () => {
    const { t } = useTranslation();
    const [user] = useState(useFirebaseAuth());
    const [dr, setDr] = useState(false);

    useEffect(()=>{
        const getStats = async () => {
            const d = new Date(Date.now()-(3600*24*1000));
            const day = `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}`;
            const sevenAgo = moment().subtract(7, 'DAYS').format('YYYY-MM-DD');
            let dStat = window.localStorage.getItem(`day_${day}`); // Lo resultadoe de ayer
            let mStat = window.localStorage.getItem(`month_${day}`);
            let yStat = window.localStorage.getItem(`year_${day}`);
            let lWeek = window.localStorage.getItem(`lastWeek_${day}`);
            if(!dStat) { 
                dStat = await loadStat(day, null, 0);
                window.localStorage.setItem(`day_${day}`, JSON.stringify(dStat));
            }
            // setDayStat(dStat);
            if(!mStat) { 
                mStat = await loadStat(day, null, 1);
                window.localStorage.setItem(`month_${day}`, JSON.stringify(mStat));
            }
            // setMonthStat(mStat);
            if(!yStat) { 
                yStat = await loadStat(day, null, 2);
                window.localStorage.setItem(`year_${day}`, JSON.stringify(yStat));
            }
            // setYearStat(yStat);
            if(!lWeek) {
                lWeek = await loadStat(day, null, 3);
                window.localStorage.setItem(`lastWeek_${day}`, JSON.stringify(lWeek));
            }
            setDr(true);
        }
        if(!dr) getStats();
        // setLastWeekStat(lWeek);
    }, [user]);

    const loadStat = async (day, from, type) => {
        let data = {}
        data.tk = user?.accessToken;
        data.service = user?.service;
        data.type = type;
        if(from) {
            data.from = from;
            data.to = day;
        } else {
            data.day = day;
        }      
        return getStatistics(data);
    }

    return (
        <React.Fragment>
            {/* <Layout>
                <MyHeader title="Dashboard" />
                <Content style={{display:"flex"}}>
                    <MyMenu /> */}
                    <div style={{margin: "0 auto", width: "80%"}}>
                        <StSimple dataReady={dr}/>
                        <StChart dataReady={dr} loadStat={loadStat} />
                        <StDonut dataReady={dr} />
                        {/* <StChart dataReady={dr} /> */}
                    </div>
                {/* </Content>
            </Layout> */}
        </React.Fragment>
    );
}

export default Dashboard;