import {
  Card,
  Col,
  Row,
} from "antd";

import DChart from "./chart/Donut";

import '../assets/css/statisticChart.css'

const StDonut = (props) => {
  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          {/* <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <DChart {...props} type='D'/>
            </Card>
          </Col> */}

          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <DChart {...props} type='M'/>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <DChart {...props} type='Y'/>
            </Card>
          </Col>
          
        </Row>
      </div>
    </>
  );
}

export default StDonut;