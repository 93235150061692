import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography } from "antd";
import { useTranslation } from 'react-i18next';
import { Spin } from "antd";
import { BarChartOutlined, 
  LineChartOutlined,
} from  "@ant-design/icons"


import { useFirebaseAuth } from "../../context/AuthContext";

import '../../assets/css/statisticChart.css'

function EChart(props) {
  const { t } = useTranslation();
  const { Title, Paragraph } = Typography;

  const [user] = useState(useFirebaseAuth())
  const [lastWeekStat, setLastWeekStat] = useState(null)
  const [yearMonths, setYearMonths] = useState(null)
  const [options, setOptions] = useState(null);
  const [series, setSeries] = useState(null);
  const [items, setItems] = useState(null);
  const [chartType, setChartType] = useState('bar'); //props.type!='7D'?'line':'bar')
  
  // Cargamos items y series que son los datos en bruto
  useEffect(()=> {
    const doIt = async () => {
      const d = new Date();
      const day = `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()-1}`;
      if(props.type == '7D')
        setLastWeekStat(JSON.parse(window.localStorage.getItem(`day_${day}`)));
      else if(props.type == '12M') {
        let mStat = window.localStorage.getItem(`month_${day}`);
        if(!mStat) { 
          mStat = await props.loadStat(day, null, 1);
          window.localStorage.setItem(`month_${day}`, JSON.stringify(mStat));
        }
        // let mYear = window.localStorage.getItem(`mYear_${day}`);
        // if(!mYear) { 
        //   mYear = await props.loadStat(day, null, 4);
        //   window.localStorage.setItem(`mYear_${day}`, JSON.stringify(mYear));
        // }
       setYearMonths(JSON.parse(window.localStorage.getItem(`month_${day}`)))
      }
    }

    doIt();
  }, [user, props.dataReady])


  // Estadistica last Week
  useEffect(()=>{
    let options = {
      chart: { type: chartType, width: "100%", height: "auto", toolbar: { show: false }},
      plotOptions: { bar: { horizontal: false, columnWidth: "55%", borderRadius: 5 }},
      dataLabels: { enabled: false },
      stroke: { show: true, width: 3, colors: ["transparent"]},
      grid: { show: true, borderColor: "#ccc", strokeDashArray: 2 },
      xaxis: {
        // categories: ["1", "2", "3", "4", "5", "6", "7"],
        labels: { show: true, align: "right", minWidth: 0, maxWidth: 160,
          style: { colors: [ "#fff", "#fff", "#fff", "#fff", "#fff", "#fff", "#fff", "#fff", "#fff", "#fff", "#fff", "#fff"] }
        },
      },
      yaxis: {
        labels: { show: true, align: "right", minWidth: 0, maxWidth: 160, 
          style: { colors: [ "#fff", "#fff", "#fff", "#fff", "#fff", "#fff", "#fff", "#fff", "#fff", "#fff", "#fff", "#fff"] }
        },
      },
  
      tooltip: {
        y: { formatter: (val) => { return val; } }
      },
    };
    let categories = []
    let series = [{
      name: t('ACTIVE_USERS'),
      color: '#fff',
      data: []
    }]
    let nU = 0, nT = 0, nK = 0, aS = 0;
    const lws = (props.type === '7D' ? lastWeekStat : yearMonths)?.filter(e => e.service === user.service)
    for(var i = 0; i < lws?.length; i++){
      const ds = typeof lws[i] == 'string' ? JSON.parse(lws[i]): lws[i];
      const dat = new Date(lws[i].date);
      const cat = props.type === '7D' ? `DAYS_${dat.getDay()}` : `MONTHS_${dat.getMonth()}`;
      categories.push(t(cat));
      series[0].data.push(ds.numUsers || 0);
      nU += ds.numUsers;
      nT += ds.totalTravels;
      nK += ds.totalKM;
      aS += ds.mediumScore;
    }
    const items = [
      { value: (nT/nU).toFixed(0), unit: t('MTRAVEL') },
      { value: (nK/nU).toFixed(2), unit: t('MKILOMETERS') },
      { value: (aS/lws?.length).toFixed(2), unit: t('MSCORE') }
    ]
    options.xaxis.categories = categories;

    setSeries(series);
    setOptions(options);
    setItems(items);
  }, [lastWeekStat, yearMonths, chartType])

  const drawChart = () => {
  //  console.log(props.type, options, series)
    return ( 
      <ReactApexChart
        className="bar-chart"
        options={options}
        series={series}
        type={chartType}
        height={220}
        key={props.type}
      />
    )
  }

  return (
    <>
      {options?.xaxis?.categories?.length>0 && series[0]?.data?.length > 0 && items?.length > 0 ?
        <>
          <div style={{width:'100%', textAlign:'right'}}>
            <BarChartOutlined onClick={() => setChartType('bar')} style={{color: chartType=='bar'?"blue":"lightgrey", fontSize: '2em' }}/>
            <LineChartOutlined onClick={() => setChartType('line')} style={{color: chartType=='bar'?"lightgrey":"blue", fontSize: '2em'}} />
            </div>
          <div id="chart">
            {drawChart()} 
          </div>
          <div className="chart-vistior">
            <Title level={5}>{t('ACTIVE_USERS')} {props.type=='7D'?(t('LAST7D')).toLowerCase():(t('YEAR')).toLowerCase()}</Title>
            <Paragraph className="lastweek">
              {props.type == '7D'? t('MEDIUMS_7D_PARAGRAPH'): t('MEDIUMS_MY_PARAGRAPH')}
            </Paragraph>
            <Row gutter>
              {items && items.map((v, index) => (
                <Col xs={8} xl={8} sm={8} md={8} key={index} style={{textAlign: 'center'}}>
                  <div className="chart-visitor-count">
                    <Title level={4}>{v.value}</Title>
                    <span>{v.unit}</span>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </>
      : 
        <div className="spin-bar-chart"><Spin size="large" /></div>
      }
    </>
  )
}

export default EChart;
