import React from "react";

import "../assets/css/_404.css";

const NotFound = () => {
    return (
        <>
            <div className="_404body">
                {<img src='assets/img/tgd/logo.png' className='_404logo' alt='kaps.es' />}
                <h1 className='_404h1' data-text="404 Not f0und">
                    <span>404 Not f0und</span>
                </h1>
            </div>
        </>
    )
}

export default NotFound;