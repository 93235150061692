import React, { useEffect, useState } from 'react'

import { Form, Row, Collapse } from 'antd';

import SpeedLine from './speeds/SpeedLine'

import '../../assets/css/speedsTable.css'
import { t } from 'i18next';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';

const SpeedsTable = (props) => {
    const [speeds, setSpeeds] = useState();
    const { spCb } = props;
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '> ${min} and < ${max}',
        },
    };


/*     useEffect(() => {
        if(props.speedsTable && props.speedsTable.length > 0) {
            //let sps = [];
        let initials = {}
        for (var i = 0; i < props.speedsTable.length; i++) {
            const s = props.speedsTable[i];
            // {limit: 30, group0: 50, group1: 51, group2: 61, group3: 71, group4: 81}
            let tos = {limit: s.limit}
            let iniSpeeds = {}
            for(var j = 0; j < 5; j++) {
                tos[`group${j}`] = s.groups[j];
            }
            initials[`limit_${s.limit}`] = { speeds: tos, coefficients: s.coefficients, penalization: s.penalization, activationTime: s.activationTime };
            //sps.push(tos);
        }
        setSpeeds(initials)
        }
    }, [props.speedsTable]) */

    const setRows = () => {
        if(props.speedsTable?.length <= 0) return(<h2>No data</h2>);
        else {
            const tms = +new Date;
            return (
                <>
                    {
                        props.speedsTable?.map((speed, idx) => {
                            return(
                                <Row key={`rowSpeedLine_${idx}_${tms}`}>
                                    <SpeedLine speed={speed} spCb={spCb} />
                                </Row>
                            )
                        })
                    }
                </>
            )
        }
    }
    
    return(
        <>
       {props.speedsTable?.length > 0 && 
            <Collapse style={{marginTop: "15px" }} defaultActiveKey={props.expanded || 'speedsTable'} >
                <CollapsePanel header={t('SPEEDS_TABLE')} > 
                    {setRows()}
                </CollapsePanel>
            </Collapse>
       }
       </>
    )
}
export default SpeedsTable;