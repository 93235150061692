import React from 'react';
import {  Row, Col, Spin, Divider, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import DChart from "./chart/Donut";
import { getDocus } from '../context/firebaseConfig';

const SimulationResults = (props) => {
    const { t } = useTranslation();
    const { name, service, results, idx, runtime} = props
    let chartScores = {...props.results};
    delete chartScores.duration;
    delete chartScores.averageSpeed;
    delete chartScores.score;
    delete chartScores.nTravels;
    delete chartScores.maxSpeed;
    delete chartScores.distance;
    delete chartScores.incidents;

    // const parseTime = (t) => {
    //     return (moment(Math.trunc(t)).format('HH:mm'));
    // }

    /*
    Para descargar los ficheros:
        score_name_idx.csv -> se pasa por parámetro
        results_name_idx.csv -> llega por parámetro no es necesario
        travels_name_idx.csv -> con el name y el runtimeId y el idx se recupera de la bd
    */
    const getSpeedsObj = (speeds) => {
        let speObj = {}
        let key = 'speeds_'
        for(var i = 0; i < speeds.length; i++) {
            const block = speeds[i];
            const keyBlock = key.concat(block.limit).concat('_');
            const groups = block.groups;
            for(var j = 1; j < groups.length; j++) {
                speObj[`${keyBlock}${j+1}`] = groups[j];
            }
            const lapses = block.lapses;
            for(var k = 2; k < lapses.length; k++) {
                speObj[`${keyBlock}lapses_${k}`] = lapses[k];
            }
            const pens = block.penalization;
            for(var n = 2; n < pens.length; n++) {
                speObj[`${keyBlock}penalization_${n}`] = pens[n];
            }
        }
        return(speObj)
    }

    const downloadFiles = async () => {
        const sname = name.replace(/-/g, '');
        // Empezamos por results
        let keys = Object.keys(results);
        let txt = '';
        let headers = ''
        for(var j=0; j < keys.length; j++) {
            const key = keys[j];
            txt = txt.concat(results[key] || 0).concat(',');
            headers = headers.concat(key).concat(',');
        }
        headers = headers.substring(0, headers.length-1);
        txt = txt.substring(0, txt.length-1);
        downloadFile(headers.concat('\n').concat(txt), `${sname}_${idx}_results.csv`);

        // Seguimos con el scoring
        const rules = runtime.scoreRules
        keys = Object.keys(rules);
        txt = '';
        headers = ''
        for(var i=0; i < keys.length; i++) {
            const key = keys[i];
            let label = key;
            if(key === 'speeds') {
                const speedsObj = getSpeedsObj(rules[key]);
                const spObjKeys = Object.keys(speedsObj);
                for(var n = 0; n < spObjKeys.length; n++) {
                    const spObjKey = spObjKeys[n];
                    txt = txt.concat(speedsObj[spObjKey]).concat(',');
                    headers = headers.concat(spObjKey).concat(',');
                }
            } else if (typeof rules[key] == 'object') {
                const rules1 = rules[key];
                const keys1 = Object.keys(rules1);
                for(var j= 0; j < keys1.length; j++) {
                    const key1 = keys1[j];
                    // estas dos y otras futuras estarína más bonitas en un aray y se mirara si estaban allí, pero hoy no me sale de los cojones
                    if(key1 !== 'accelerationL' && key1 !== 'brakingL') {
                        label = key.concat('_').concat(key1);
                        txt = txt.concat(rules1[key1] || 0).concat(',');
                        headers = headers.concat(label).concat(',');
                    }
                }
            } else {
                txt = txt.concat(rules[key] || 0).concat(',');
                headers = headers.concat(key).concat(',');
            }
        }
        headers = headers.substring(0, headers.length-1);
        txt = txt.substring(0, txt.length-1);
        downloadFile(headers.concat('\n').concat(txt), `${sname}_${idx}_rules.csv`);

        // Terminamos con los viajes
        const docus = await getDocus({coll:`SIM_${service.toUpperCase()}_${name.replace(/-/g, '')}_${idx}`, service: `${service === 'TGD' ? '' : service}`})
        headers = ['date', 'distance', 'duration', 'endLocation', 'endTime', 'endZipCode', 'initialLocation', 'initialTime', 'initialZipCode', 'score', 'user', 
                    'max_speed', 'average_speed', 'incidents', 'curves', 'phoneUsage', 'accelerations', 'brakes'];
        txt = '';
        for(var sp = 30; sp <=120; sp+=10) {
            for(var lp=2; lp <=5; lp++) {
                headers.push(`speed_${sp}_${lp}`);
            }
        }      
        for(var k = 0; k < docus?.length; k++) {
            const current = docus[k];
            for(var n = 0; n < headers.length; n++) {
                if(n <= 10){
                    txt = txt.concat(current[headers[n]] || 0).concat(',');
                } else {
                    const head = headers[n];
                    const idx = current.incidents.findIndex( ele => ele.id === head );

                    let value = null;
                    if(head === 'average_speed'|| head === 'max_speed') {
                        value = idx >= 0 ? current.incidents[idx].value : ''
                    } else {
                        value = idx >= 0 ? Number(current.incidents[idx].value) : 0;
                    }
                    txt = txt.concat(value).concat(',');
                }
            }
            txt = txt.substring(0, txt.length-1);
            txt = txt.concat('\n');
        }
        let heads = headers.toString();
        txt = txt.substring(0, txt.length-1);
        downloadFile(heads.concat('\n').concat(txt), `${sname}_${idx}_travels.csv`);
    }

    const downloadFile = (txt, filemon) => {
        // It works on all HTML5 Ready browsers as it uses the download attribute of the <a> element:
        const element = document.createElement('a');  
        //A blob is a data type that can store binary data
        // "type" is a MIME type
        // It can have a different value, based on a file you want to save
        const blob = new Blob([txt], { type: 'plain/text' });
        //createObjectURL() static method creates a DOMString containing a URL representing the object given in the parameter.
        const fileUrl = URL.createObjectURL(blob);
        //setAttribute() Sets the value of an attribute on the specified element.
        element.setAttribute('href', fileUrl); //file location
        element.style.display = 'none';
        element.setAttribute('download', filemon); // file name
        //use appendChild() method to move an element from one element to another
        document.body.appendChild(element);
        element.click();
        //The removeChild() method of the Node interface removes a child node from the DOM and returns the removed node s
        document.body.removeChild(element);
    };

    if(!results || Object.keys(results)?.length <= 0) {
        return (
            <Row span={24} style={{justifyContent: 'center'}} className={`resultBox ${idx >= 0 ? ' resultBoxB' : ''}`}>
                <Spin size="large" />
            </Row>
        )
    } else {
        return (
            <Row span={24}>
                <Col span={8}  className='resultsGlobal'>
                    <div>
                        <span className='titleLine'>{ name } ({idx})</span>
                    </div>
                    <Divider className='divider' />
                    <div className='flexCol'>
                        <span className='labelLine'>{t('NUM_INCIDENTS')}</span>
                        <span className='valueLine'>{`${(results.incidents/results.nTravels).toFixed(2)} ${t('PER_TRAVEL')} `}</span>
                        <span className='valueLine'>{`${results.incidents} ${t('TOTAL')}`}</span>
                    </div>
                    <Divider className='divider' />
                    <div className='flexCol'>
                        <span className='labelLine'>{t('SCORE_RESULT')}</span>
                        <span className='valueLine scoreLine'>{`${(results.score/results.nTravels).toFixed(2)}`}</span>
                    </div>
                    <div className='flexCol df'>
                    <Button type="primary" icon={ <DownloadOutlined /> } size='large' onClick={() => downloadFiles()}>{t('DOWNLOAD_FILES')}</Button>
                    </div>
                </Col>
                <Col span={16} >
                    <DChart type='C' data={[{service: service, data: JSON.stringify({scores: chartScores})}]} />
                </Col>
            </Row>
        )
    }
}

export default SimulationResults;