import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { app, getAuth, getWatchingHook, getUser, logOut, getHook, getDocu } from "./firebaseConfig";
import Loading from '../components/Loading';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

const Auth = React.createContext({ name: '', user: null, menu: null });

const AuthContext = ({ children }) => {
    const [audio] = useState(new Audio('https://firebasestorage.googleapis.com/v0/b/api-kaps.appspot.com/o/bell2.mp3?alt=media&token=d3fdd7a4-8149-4c74-8c11-871b98748f35'));
    const nav = useNavigate();
    const loc = useLocation();
    const { t } = useTranslation();
    const [user, setUsuario] = useState(null);
    const [showChild, setShowChild] = useState(false);
    const [sessionID, setSessionID] = useState();
    const [isPlayed, setIsPlayed] = useState(false);
    const [viewedAlerts, setViewedAlerts] = useState([]); // Estado para almacenar las alertas vistas

    // Flag to track if audio is allowed to play
    const [audioAllowed, setAudioAllowed] = useState(false);

    // Event handler to enable audio on user interaction
    const enableAudio = useCallback(() => {
        setAudioAllowed(true);
        document.removeEventListener('mousemove', enableAudio);
    }, []);

    useEffect(() => {
        const au = getAuth(app);
        au.onAuthStateChanged(async (user) => {
            const cus = au.currentUser;
            const hayUser = cus != null;
            if (hayUser) {
                const dbUser = await getUser(user?.email);
                if (dbUser) {
                    const service = await getDocu({ coll: 'services', docu: dbUser.service });
                    if (!service) console.log(`No hay service ${dbUser.service} configurado en BD`);
                    user.rol = dbUser.rol;
                    user.service = dbUser.service;
                    user.sessionID = dbUser.sessionIDs[0];
                    user.configs = {
                        timezone: service?.timezone || 'Europe/Madrid',
                        mapCenter: service?.mapCenter || { lat: 39.5, lng: -3.5 },
                        mapZoom: service?.mapZoom || 5
                    };
                }
                setUsuario(user);
                const storedViewedAlerts = JSON.parse(localStorage.getItem('viewedAlerts')) || [];
                setViewedAlerts(storedViewedAlerts); // Recuperar alertas vistas desde localStorage
                setIsPlayed(false); // Reiniciar el estado de reproducción
            } else {
                setUsuario(null);
            }
            setShowChild(true);
        });
    }, []);

    useEffect(() => {
        if (user) {
            const ssId = localStorage.getItem('sessionID');
            setSessionID(ssId);
            const unsubscribe = getWatchingHook({ coll: 'users', alertId: `${user?.email}` },
                (querySnapshot) => {
                    const d = querySnapshot.data();
                    if (!d.sessionIDs || isNaN(ssId) || Number(ssId) !== d.sessionIDs[0]) {
                        window.localStorage.clear();
                        nav('/login');
                    }
                },
                (error) => alert('kk de la vaca')
            );
            return unsubscribe;
        }
    }, [user]);

    useEffect(() => {
        if (loc.pathname === '/' || loc.pathname === '/login') return null; // para q no salga notif en login

        // la campana de alertas sonará cuando hacemos login y hay alertas sin ser vistas (no hay campo viewed) o cuando se crea una alerta nueva en la coll alerts, el resto de cambios no provocan que suene la campana. Añadimos un escuchador a la collection alerts para que permita que suene la campanita si se cumplen las condiciones. Ademas, se debe mostrar una notificacion por cada nueva alert
        if ((user?.service && (user?.rol[0]).includes('OPERATOR')) || (user?.service && (user?.rol[0]).includes('ADMIN'))) {
            let hook = getHook({ coll: 'alerts', order: 'alertId', service: user.service, lim: null },
                (snap) => {
                    snap.docChanges().forEach((change) => {
                        if (change.type === "added" || change.type === "modified" ) { 
                            // console.log("New alert: ", change.doc.data().alertId);
                            const alertId = change.doc.data().alertId;
                            const viewed = change.doc.data().viewed;
                            const cancelled = change.doc.data().cancelled;
                            let visible = false;
                            
                            if(user.service == 'AHP' || user.service == 'REP' || user.service == 'TGD' || user.service == 'REMOTO2' || user.service == 'CASERCC' || user.service == 'DUCATI' || user.service == 'KYMCO' || user.service == 'AUT' || user.service == 'PUS') {
                                // remoto 2 tiene como services REMOTO2, DUCATI, KYMCO... confirmar que cada uno solo debe mostrar llas alertas asociadas, que es como está hecho. 
                                visible = change.doc.data().visible || false;
                            } else {
                                //para SNOWFY que no lleva este campo visible, se lo marcamos por defecto
                                visible = true;
                            }

                            if ((visible) && (!viewed || viewed === false) && (!cancelled || cancelled === false)) {
                                if (!viewedAlerts.includes(alertId)) {
                                    setViewedAlerts(prevState => {
                                        const newViewedAlerts = [...prevState, alertId];
                                        localStorage.setItem('viewedAlerts', JSON.stringify(newViewedAlerts)); // Guardar en localStorage
                                        return newViewedAlerts;
                                    });

                                    // Intentar reproducir el audio en una pequeña promesa que maneje posibles excepciones
                                    if (audioAllowed) {
                                        audio.play().catch(function (error) {
                                            console.log("Chrome cannot play sound...", error);
                                            return null;
                                        });
                                    }
                                    // Mostrar notificación durante 5 segundos si aun no se ha mostrado
                                    notification.open({
                                        message: t('MSG_NEW_ALERT_TITLE'),
                                        description: t('MSG_NEW_ALERT_DESCRIPTION'),
                                    });
                                    setIsPlayed(true);
                                    setTimeout(() => {
                                        notification.destroy(); // Cerrar la notificación después de 5 segundos
                                    }, 5000);

                                    setTimeout(() => {
                                        // notification.destroy(); // Cerrar la notificación después de 5 segundos
                                        setIsPlayed(false); // Reiniciar isPlayed después de cierto tiempo para permitir otra reproducción
                                    
                                    }, 500);
                                }
                                // } else {
                            //     // me hacia cosas raras la app con este else, asi que si funciona bien solo con el if, quitarlo de aqui
                            //     audio.pause();
                            }
                        }
                        // if (change.type === "modified") {
                        //     console.log("Modified alert: ", change.doc.data());
                        // }
                        // if (change.type === "removed") {
                        //     console.log("Removed alert: ", change.doc.data());
                        // }
                    });
                },
                (error) => console.log(`kk de la vaca asturiana, ${error}`)
            );
            return hook;
        }
    }, [user, loc.pathname, viewedAlerts, audioAllowed]);

    //Con este useeffect tratamos de capturar alguna accion del usuario para evitar el caso de que refresca el navegador y no suena la campana por el error de Google que ya no permite sonidos automaticos sin accion del usuario. Tenemos la mayoria de los posibles casos controlados, pero si el usuario refresca la pagina y no hace nada mas, esto no funcionará, aparecerá la notificación, pero no sonará la campana. TODO:IMPORTANTE
    useEffect(() => {
        // Attach the event listener for mouse movement
        document.addEventListener('mousemove', enableAudio);
        return () => {
            // Clean up the event listener
            document.removeEventListener('mousemove', enableAudio);
        };
    }, [enableAudio]);

    if (!showChild) {
        return <Loading />;
    } else {
        return (
            <Auth.Provider value={{ user }} key={+new Date}>
                {children}
            </Auth.Provider>
        );
    }
};

function useFirebaseAuth() {
    const context = React.useContext(Auth);
    if (context === undefined) {
        console.log("useFirebaseAuth must be used within a FirebaseAuthProvider")
        throw new Error(
            "useFirebaseAuth must be used within a FirebaseAuthProvider"
        );
    }
    return context.user;
}

export { Auth, AuthContext, useFirebaseAuth };
